.SubCategoryProductCard{
    background: #f9f9f9;
    border-radius: 15px;
    margin-top: 10px;
    position: relative;
}

.SubCategoryProductCard .img_holder{
    
}

.SubCategoryProductCard .img_holder .card_img{
    aspect-ratio: 1 / 1;
    border-radius: 15px;
    height: 100%;
    width: 100%;
}

.SubCategoryProductCard .text_holder {
    bottom: 4%;
    left: 0;
    position: absolute;
    z-index: 1;
    right: 0;
}

.SubCategoryProductCard .text_holder .product_name {
    color: #fff;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 0px;
}

.SubCategoryProductCard .overlay_bg {
    background: transparent linear-gradient(180deg, #0000004d, #0000003d) 0 0 no-repeat padding-box;
    border-radius: 0px 0px 15px 15px;
    bottom: 0;
    height: 20%;
    left: 0;
    position: absolute;
    right: 0;
    transition: .2s ease-in-out;
    width: 100%;
}

.SubCategoryProductCard:hover .overlay_bg {
    background: transparent linear-gradient(180deg, #0000007a, #0000003d) 0 0 no-repeat padding-box;
    height: 100%;
    border-radius: 15px 15px;
}


@media (min-width:0px) and (max-width:450px) {
    .SubCategoryProductCard .text_holder .product_name {
        font-size: 13px;
    }

    .SubCategoryProductCard .overlay_bg {
        height: 34%;
    }
}


@media (min-width:768px) and (max-width:991.90px) {
    .SubCategoryProductCard .text_holder .product_name {
        font-size: 13px;
    }
}




