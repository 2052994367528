.product-banner {
    background: #F3F5F7 0% 0% no-repeat padding-box;
    padding: 46px 0px;

}

.product-banner .circle-holder {
    border: 4px solid #0F3768;
    border-radius: 50px;
    width: 98px;
    height: 98px;
}

.product-banner .circle-holder .ecartlogo {
    width: 90px;
    height: 90px;
    border-radius: 50px;
}

.product-banner .name-holder h1 {
    font-size: 30px;
    font-weight: 600;
    color: #000;
    margin-top: 32px;
    margin-left: 40px;
}

@media(min-width:0px) and (max-width:767px) {
    .product-banner .name-holder h1 {
        font-size: 21px;
        margin-left: 15px;
        margin-top: 28px;
    }

    .product-banner .circle-holder {
        width: 80px;
        height: 80px;
    }

    .product-banner .circle-holder .ecartlogo {
        width: 72px;
        height: 72px;

    }
}