
.mySwiper  .swiper-pagination-bullet {
    background: #f99a1a;
    opacity: 1;
}

.mySwiper .swiper-pagination-bullet-active {
    opacity: var(--swiper-pagination-bullet-opacity, 1);
    background: #f99a1a;
}
