.Coupan .coupancardMain .coupancard {
    border-radius: 24px;
    background: linear-gradient(to right, #7cc4c3 0%, #479493 100%);
    border: 0;
    position: relative;
    margin-bottom: 5%;
}

.Coupan .coupancardMain .coupancard::before {
    transform: translate(-50%, -50%);
    top: 50%;
    right: -36px;
    position: absolute;
    content: '';
    display: block;
    height: 45px;
    width: 45px;
    border-radius: 100px;
    background-color: #fff;
}

.Coupan .coupancardMain .coupancard::after {
    transform: translate(-50%, -50%);
    top: 50%;
    left: 3px;
    position: absolute;
    content: '';
    display: block;
    height: 45px;
    width: 45px;
    border-radius: 50%;
    background-color: #fff;
}

.Coupan {
    margin-bottom: 12%;
    margin-top: 3%;
}

.Coupan .coupancardMain .coupancard .card-details {
    display: flex;
    margin-bottom: 10px;
}

.Coupan .coupancardMain .coupancard .card-details h3 {
    font-size: 13px;
    color: #fff;
    margin-bottom: 0px;
    margin-top: 2px;
    margin-right: 15px;
}

.Coupan .coupancardMain .headingtxt h3 {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 3%;
}

.Coupan .coupancardMain .coupancard .card-details p {
    font-size: 13px;
    color: #fff;
    margin-bottom: 0px;
    border-bottom: 1px solid #fff;
}

.Coupan .coupancardMain .coupancard p {
    font-size: 16px;
    color: #fff;
}

.Coupan .coupancardMain .coupancode .codetxt {
    font-size: 13px;
    text-align: end;
    margin-bottom: 0;
}

.Coupan .coupancardMain .coupancode .codetxt span:nth-of-type(1) {
    font-weight: 600;
    margin-left: 4px;
}

.Coupan .coupancardMain .coupancode .codetxt span:nth-of-type(2) {
    font-size: 16px;
}

.Coupan .coupancardMain .card .card-body .border-card {
    border: dashed 1px white;
}

.Coupan .coupancardMain .card .card-body .main-images .image img.card-img {
    height: 40px;
    width: 40px;
}

.Coupan .coupancardMain .card .card-body .main-images {
    display: flex;
}

.Coupan .coupancardMain .card .card-body .main-images .image {
    margin: 7px 4px;
}

.Coupan .coupancardMain .card .card-body {
    padding: 34px 38px;
}

@media (min-width:0px) and (max-width:320px) {
    .Coupan {
        margin-bottom: 30%;
    }
}

@media (min-width:321px) and (max-width:485px) {
    .Coupan {
        margin-bottom: 26%;
    }
}