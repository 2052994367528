.Product_Cart {
  margin-top: 2%;
  margin-bottom: 12%;
}


.product_table .tabless .cabbageimg .cartimg {
  width: 100%;
  height: 115px;
  object-fit: contain;
}

.product_table .tabless .prices .qnty {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.product_table .tabless .prices .counting {
  display: flex;
  align-items: center;
}

.product_table .tabless .prices .count-btn .minnusicns {
  font-size: 8px;
  color: #fff;
}

.product_table .tabless .prices .count-btn .plusicns {
  font-size: 8px;
  color: #000;
}

.product_table .tabless .cabbage {
  font-size: 16px;
  font-weight: bold;
  color: #323232;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;

}

.product_table .tabless .cabbage2 {
  color: transparent !important;
}

.product_table .tabless .prices .prce {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0px;
  color: #323232;
  opacity: 1;
  display: flex;
  justify-content: space-between;
  text-wrap: nowrap;

}

.product_table .tabless .prices .prce span {
  font-weight: 400;
  font-size: 12px;
  margin-top: auto;
  margin-left: 7px;
  margin-bottom: auto;
}

.product_table .tabless .prices .nombers {
  margin: 0px 20px;
}

.product_table .tabless .prices .count-btn {
  width: 22px;
  height: 22px;
  background: #d1d1d1 0% 0% no-repeat padding-box;
  opacity: 1;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.product_table .tabless .prices .counting {
  margin-left: 25px;
}

.product_table .row.tabless {
  flex-wrap: nowrap;
  overflow-x: scroll;
  scrollbar-width: thin;
}

/* table replCE CSS end */

.Product_Cart {
  margin-top: 2%;
  margin-bottom: 12%;
}

.threeorder {
  margin-bottom: 0px !important;
}

.Product_Cart .heading_holder .breadcrumb-item+.breadcrumb-item::before {
  /* content: url("../../../../public/assets/Image/Icon/arrow-forward.png"); */
  content: url("../../../public/assets//Image//Icon//arrow-forward.png");
}

.Product_Cart .heading_holder .breadcrumb-item {
  font-size: 13px;
  font-weight: 400;
  cursor: pointer;
  color: #000;
}

.Product_Cart .heading_holder .breadcrumb-item.active a {
  font-size: 13px;
  font-weight: 400;

  color: #000;
}

.Product_Cart .heading_holder .breadcrumb-item a {
  font-size: 13px;
  font-weight: 600;

  color: #0f3768;
}

.Product_Cart .heading_holder h4 span {
  text-align: left;
  font-size: 13px;
  font-weight: 500;
  color: #0f3768;
}

.Product_Cart .product_table .table {
  --bs-table-bg: none;
}

.Product_Cart .product_table .Product_Cart_img {
  text-align: center;
}

.Product_Cart .product_table .Product_Cart_img .cart_img {
  width: 50px;
  height: 50px;
}

.Product_Cart .product_table tbody tr td {
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  color: #323232;
  padding: 30px 19px;
  border: none;
}

.Product_Cart .product_table tbody tr .view-text {
  font-weight: 500;
  color: #000000;
  cursor: pointer;
}

.Product_Cart .product_table tbody tr .red-text {
  font-weight: 500;
  color: #ec0000;
}

.Product_Cart .product_table tbody tr .green-text {
  font-weight: 500;
  color: #43aa00;
}

.Product_Cart .product_table tbody tr .yellow-text {
  color: #deaa00;
  font-weight: 500;
}

.Product_Cart .product_table tbody tr .return-text {
  color: #FB8600;
  font-weight: 500;
}

.Product_Cart .product_table tbody tr .cancel-text {
  color: #000;
  font-weight: 500;
}

.Product_Cart .product_table thead tr th {
  text-align: center;
  font-size: 15px;
  font-weight: 600;
  padding: 15px 10px;
  color: #323232;
  background: #f6f6f6;
  white-space: nowrap;
  border: none;
}

.Product_Cart .product_table tr {
  border-style: none;
}

.Product_Cart .Continue_Shopping_text p {
  text-align: left;
  font-size: 14px;
  font-weight: 600;
  color: #0f3768;
}

.Product_Cart .product_table .blue-bg {
  background: #0f3768;
  border-radius: 50px;
  /* padding: 5px; */
  width: 132px;
  height: 38px;
  justify-content: space-between;
}

.Product_Cart .product_table .circle-holder1 {
  border-radius: 100%;
  background: #fff;
  width: 32px;
  height: 32px;
  margin: 3px;
}

.Product_Cart .product_table .blue-bg .minus-btn {
  border-radius: 100%;
  color: #df4223;
  background: #fff;
  border: 1px solid #df4223;
  /* padding: 0px 11px; */
  width: 28px;
  height: 28px;
  margin: 2px;
}

.Product_Cart .product_table .blue-bg .number-text {
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.grand_total_table {
  background-color: #ecf5ff;
  padding: 10px 20px;
  border-radius: 5px;
  margin-bottom: 18px;
}

.grand_total_table .orderss .ordersum {
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  letter-spacing: 0px;
  color: #323232;
  opacity: 1;
}

.grand_total_table .table {
  --bs-table-bg: #ecf5ff;
  margin: 0;
}

.grand_total_table .top-border {
  border-top: 1px solid #ecf5ff;
  border-bottom: 1px solid #707070;
}

.grand_total_table .bottom-border {
  border-bottom: 1px solid #ecf5ff;
}

.grand_total_table thead tr th {
  text-align: left;
  font-size: 14px;
  font-weight: 600;
  color: #323232;
  border: none;
  padding: 10px 0px;
  width: 50%;
}

.grand_total_table thead tr td {
  text-align: right;
  font-size: 14px;
  font-weight: 400;
  color: #323232;
  border: none;
  padding: 10px 0px;
}

.grand_total_table thead tr .bold-text {
  font-size: 18px;
  font-weight: 600;
  color: #323232;
}

.grand_total_table thead tr .Coupon_code {
  text-align: left;
  font-size: 14px;
  font-weight: 500;
  color: #a2a2a2;
  padding: 10px 0px;
}

.grand_total_table .Coupon_code_holder {
  border-radius: 5px;
}

.grand_total_table .Coupon_code_holder .form-control {
  font-size: 14px;
  font-weight: 400;
  color: #a2a2a2;
  border-radius: 5px;
  border: none;
  background: #ffffff;
}

.grand_total_table .Coupon_code_holder .form-control:focus {
  box-shadow: none;
}

.grand_total_table .Coupon_code_holder .Checkout_btn {
  background: #212121 0% 0% no-repeat padding-box;
  border: 1px solid #212121;
  border-radius: 5px;
  font-size: 13px;
  color: #ffffff;
  font-weight: 400;
}

.grand_total_table .Coupon_code_holder .Checkout_btn:active {
  background: #212121 0% 0% no-repeat padding-box;
  border: 1px solid #212121;
}

.grand_total_table .Coupon_code_holder td {
  padding: 10px 0px;
}

.grand_total_table .Coupon_code_holder th {
  padding: 0;
}

.grand_total_table .Checkout_btn {
  background: #212121 0% 0% no-repeat padding-box;
  border: 1px solid #212121;
  border-radius: 5px;
  font-size: 14px;
  color: #ffffff;
  font-weight: 400;
  width: 100%;
}

.grand_total_table .Checkout_btn:active {
  background: #212121 0% 0% no-repeat padding-box;
  border: 1px solid #212121;
}

.grand_total_table .Checkout_btn:hover {
  background: #212121 0% 0% no-repeat padding-box;
  border: 1px solid #212121;
}

.grand_total_table .Checkout_btn:active {
  background: #212121 0% 0% no-repeat padding-box !important;
  border: 1px solid #212121;
}

button.procheckout_btn.btn.btn-primary {
  width: 100%;
  height: 38px;
  background: #0f3768 0% 0% no-repeat padding-box;
  border-radius: 19px;
  font-size: 14px;
  font-weight: 300;
  border: none;
  letter-spacing: 0.41px;
  color: #ffffff;
  opacity: 1;
}

@media (min-width: 0px) and (max-width: 321px) {
  .Product_Cart {
    margin-bottom: 40%;
  }

  .threeorder {
    margin-bottom: 0px !important;
  }

  .tabless .cabbageimg .cartimg {
    width: 100%;
    height: 100px;
  }

  .tabless .prices .cabbage {
    font-size: 13px;
  }

  .tabless .prices .prce {
    font-size: 11px;
  }

  .tabless .prices .counting {
    margin-left: 15px;
  }

  .tabless .prices .nombers {
    margin: 0px 5px;
  }
}

@media (min-width: 321px) and (max-width: 486px) {
  .Product_Cart {
    margin-bottom: 35%;
  }

  .threeorder {
    margin-bottom: 0px !important;
  }

  .product_table .tabless .cabbageimg .cartimg {
    height: 100px;
  }

  .product_table .tabless .prices .cabbage {
    font-size: 14px;
  }

  .product_table .tabless .prices .prce {
    font-size: 12px;
  }

  .product_table .tabless .prices .counting {
    margin-left: 15px;
  }

  .product_table .tabless .prices .nombers {
    margin: 0px 5px;
  }
}

@media (min-width: 486px) and (max-width: 567px) {
  .Product_Cart {
    margin-bottom: 30%;
  }

  .threeorder {
    margin-bottom: 0px !important;
  }

  .product_table .tabless .cabbageimg .cartimg {
    height: 100px;
  }

  .product_table .tabless .prices .cabbage {
    font-size: 14px;
  }

  .product_table .tabless .prices .prce {
    font-size: 12px;
  }

  .product_table .tabless .prices .counting {
    margin-left: 15px;
  }

  .product_table .tabless .prices .nombers {
    margin: 0px 5px;
  }
}

@media (min-width: 568px) and (max-width: 767px) {
  .Product_Cart {
    margin-bottom: 25%;
  }

  .threeorder {
    margin-bottom: 0px !important;
  }

  .product_table .tabless .prices .cabbage {
    font-size: 15px;
  }

  .product_table .tabless .prices .prce {
    font-size: 13px;
  }

  .product_table .tabless .prices .counting {
    margin-left: 15px;
  }

  .product_table .tabless .prices .nombers {
    margin: 0px 5px;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .Product_Cart {
    margin-bottom: 20%;
  }

  .threeorder {
    margin-bottom: 0px !important;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .Product_Cart {
    margin-bottom: 15%;
  }

  .threeorder {
    margin-bottom: 0px !important;
  }
}