.vegetable-sec {
  background-color: #f8f8f8;
  height: 269px;
  margin-bottom: 3%;

}

.vegetable-sec .nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  background: #df4223 0% 0% no-repeat padding-box;
  color: #fff;
  border-radius: 0%;
  font-size: 15px;
  border: none;
  border-radius: 0%;
}

.vegetable-sec .nav-pills .nav-link:hover {
  background: #df4223 0% 0% no-repeat padding-box;
  color: #fff;
  border-radius: 0%;
  font-size: 15px;
  border: none;
  border-radius: 0%;
}

.vegetable-sec .nav-pills .nav-link {
  color: #000;
  font-size: 15px;
  border: none;
  border-radius: 0%;
}

.vegetable-sec .product-name h2 {
  font-size: 22px;
  font-weight: 600;
  color: #333333;
}



.vegetable-sec .text-overlay p {
  font-size: 15px;
  font-weight: bold;
  color: #df4223;
  text-align: center;
}

.vegetable-sec .text-overlay h5 {
  color: #25553e;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}

.vegetable-sec .percentage {
  font-size: 15px;
  font-weight: bold;
  color: #25553e !important;
  text-align: center;
}

.vegetable-sec .namefruit {
  color: #df4223 !important;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}

.vegetable-sec .text-overlay .button-holder {
  margin-top: 105px;
}

.vegetable-sec .text-overlay .button-holder .btn-show {
  background: #fff 0% 0% no-repeat padding-box;
  border-radius: 23px;
  font-size: 20px;
  font-weight: bold;
  color: #0f3768;
  padding: 5px 15px;
  border: none;
}

.vegetable-sec .text-overlay .button-holder .btn-show:hover {
  background: #0f3768 0% 0% no-repeat padding-box;
  border-radius: 23px;
  font-size: 20px;
  font-weight: bold;
  color: #fff;
  border: none;
}


.vegetable-sec .veggies-section .image-main {
  width: 100%;
  height: 165px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: contain !important;
  aspect-ratio: 1/1;
  background-position: center;
}

.vegetable-sec .veggies-section .image-main .cabbage {
  width: 117px;
  height: auto;
  object-fit: contain;
  aspect-ratio: 1/1;
}

.vegetable-sec .veggies-section .veggies.card .card-body .price {
  display: flex;
  margin-bottom: -30px;
}

.vegetable-sec .veggies-section .veggies.card .card-body .weight1 {
  font-weight: 500;
  font-size: 12px;
  color: #df4223;
  font-family: "Roboto" !important;
}

.vegetable-sec .silder-btn {
  position: relative;
}



.vegetable-sec .silder-btn .back-btn {
  content: url("../../../../../public/assets/Image/Homepage/Popular-Categories/left-1.png");
  width: 20px;
  height: 20px;
  margin: 10px;
  cursor: pointer;
  position: absolute;
  bottom: 170px;
  right: -20px;
  z-index: 1;
}

.vegetable-sec .silder-btn .next-btn {
  content: url("../../../../../public/assets/Image/Homepage/Popular-Categories/right-1.png");
  width: 20px;
  height: 20px;
  margin: 10px;
  cursor: pointer;
  position: absolute;
  bottom: 148px;
  z-index: 999;
  right: -20px;
  z-index: 1;
}

.vegetable-sec .swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
  display: none;
}

.vegetable-sec .swiper-button-prev:after,
.swiper-button-next:after {
  display: none;
}

.vegetable-sec .veggies.card .card-body {
  padding: 0px 9px;
}

.vegetable-sec .veggies.card .card-body .cart .background button.btn.btn-like {
  padding: 4px 30px;
  border: none;
  height: 32px;
  background: #ffccc2;
  border-radius: 16px;
  transform: translateY(-55px);
  transition: transform 0.3s ease;
}

.vegetable-sec .veggies.card:hover .card-body .cart .background button.btn.btn-like {
  transform: translateY(0);
  animation: slide-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}


@media (min-width: 0px) and (max-width: 577px) {
  .vegetable-sec .text-overlay .button-holder .btn-show {
    font-size: 14px;
  }

  .vegetable-sec .text-overlay .button-holder .btn-show:hover {
    font-size: 14px;
  }



  .vegetable-sec {
    height: auto;
  }

  .vegetable-sec .cart .background {
    justify-content: center;
  }
}

@media (min-width: 578px) and (max-width: 767px) {
  .vegetable-sec .text-overlay .button-holder .btn-show {
    font-size: 14px;
  }

  .vegetable-sec .text-overlay .button-holder .btn-show:hover {
    font-size: 14px;
  }


  .vegetable-sec {
    height: auto;
  }

  .vegetable-sec .image-holder .banlogo {
    width: 220px;
    height: 269px;
  }


}

@media (min-width: 768px) and (max-width: 991px) {
  .vegetable-sec .text-overlay .button-holder .btn-show {
    font-size: 14px;
  }

  .vegetable-sec .text-overlay .button-holder .btn-show:hover {
    font-size: 14px;
  }


  .vegetable-sec .nav-pills .nav-link.active,
  .nav-pills .show>.nav-link {
    font-size: 14px;
  }

  .vegetable-sec .nav-pills .nav-link {
    font-size: 14px;
  }


  .vegetable-sec .image-holder .banlogo {
    width: 245px;
    height: 269px;
  }


}

@media (min-width: 992px) and (max-width: 1200px) {
  .vegetable-sec .text-overlay .button-holder .btn-show {
    font-size: 16px;
  }

  .vegetable-sec .text-overlay .button-holder .btn-show:hover {
    font-size: 16px;
  }

  .vegetable-sec .text-overlay {
    margin-left: 30px;
  }

  .vegetable-sec .image-holder .banlogo {
    width: 208px;
    height: 269px;
  }



  .vegetable-sec .text-overlay h5 {
    font-size: 18px;
  }
}