.Order_Cancle .product-section-div {
  margin-bottom: 30px;
}

.Order_Cancle .product-section-div .text-holder {
  margin-top: 1%;
}

.Order_Cancle .product-section-div .text-holder p {
  font-family: "Roboto" !important;
  font-size: 14px;
  font-weight: 500;
  color: #323232;
  margin-bottom: 5px;
}

.Order_Cancle .product-section-div .text-holder h4 {
  font-family: "Roboto" !important;
  font-size: 18px;
  font-weight: 600;
  color: #323232;
  margin-bottom: 0px;
}

.Order_Cancle .product-section-div .text-holder span {
  font-family: "Roboto" !important;
  font-size: 14px;
  font-weight: 400;
  color: #323232;
}

.Order_Cancle .Cancellation {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 9px #00000021;
  border-radius: 5px;
  padding: 9px 15px;
  margin-bottom: 30px;
}

.Order_Cancle .Cancellation p {
  font-family: "Roboto" !important;
  font-size: 16px;
  font-weight: 500;
  color: #323232;
  margin-bottom: 0px;
}

.Order_Cancle .Cancellation-reason p {
  margin-bottom: 15px;
}

.Order_Cancle .Cancellation-reason span {
  font-family: "Roboto" !important;
  font-size: 14px;
  font-weight: 400;
  color: #323232;
}

.Order_Cancle .Cancellation-reason.form-control {
  padding: 0px;
  border: none;
  border-bottom: 2px solid #00000021;
  border-radius: 0px;
  font-size: 14px;
  font-weight: 500;
}

.Order_Cancle .Cancellation-reason .btn-holder {
  text-align: center;
  margin-top: 2%;
  margin-bottom: 2%;
}


.Order_Cancle .Cancellation-reason .form-control {
  border-left: none;
  border-right: none;
  border-top: none;
  font-size: 14px;
}

.Order_Cancle .Cancellation-reason .btn-holder .submit-btn {
  font-Size: 18px;
  padding: 5px 30px;
  margin: 0px 5px;
  background-color: #000;
  border: 1px solid #000;
  border-radius: 5px;
  color: #FFF;
}

.Order_Cancle .Cancellation-reason .btn-holder .cancel-btn {
  font-Size: 18px;
  padding: 5px 30px;
  margin: 0px 5px;
  background-color: #b7b7b7;
  border: 1px solid #b7b7b7;
  border-radius: 5px;
  color: #FFF;
}

@media (min-width: 280px) and (max-width: 768px) {
  .Order_Cancle .Cancellation p {
    font-size: 14px;
  }

  .Order_Cancle .product-section-div .text-holder h4 {
    font-size: 15px;
  }

  .Order_Cancle .Cancellation-reason .btn-holder .submit-btn {
    font-size: 15px;
  }

  .Order_Cancle .Cancellation-reason .btn-holder .cancel-btn {
    font-size: 15px;
  }
}