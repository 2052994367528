.veggies-section .veggies .like-heart .heart-icon.active {
  color: #f99a1a;
}

.veggies-section {
  height: auto;
  border: 1px solid #f053403b;
}

.veggies-section .veggies .cart-btn {
  display: flex;
  justify-content: end;
  margin-top: 15px;
}

.veggies-section .veggies .like-heart {
  display: flex;
  justify-content: end;
  margin-top: 0px;
  padding: 0px 9px;
}

.veggies-section .veggies .card-body {
  padding: 0px 9px;
}

.veggies-section .veggies .background .btn-like {
  padding: 4px 30px;
  border: none;
  height: 32px;
  background: #ffccc2;
  border-radius: 16px;
}

.veggies-section .veggies .background .button-holder {
  background: none;
  border: none;
}

.veggies-section .veggies .background .button-holder:hover {
  background: none;
  border: none;
}

.veggies-section .veggies .background .button-holder:active {
  background: none;
  border: none;
}

.veggies-section .veggies .card-body .price {
  display: flex;
  margin-bottom: -30px;
}

.veggies-section .veggies .card-body .weight1 {
  font-weight: 500;
  font-size: 12px;
  color: #df4223;
  font-family: "Roboto" !important;
}

.veggies-section .veggies .card-body .weight3 {
  font-weight: 500;
  font-size: 12px;
  color: #000;
  font-family: "Roboto" !important;
  text-decoration: line-through;
}

.veggies-section .veggies .card-body .card-heading {
  font-weight: 600;
  font-size: 14px;
  color: #000;
  font-family: "Roboto" !important;
  margin-bottom: 0px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 1;
}

.veggies-section .veggies .image-main {
  width: 100%;
  height: 165px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: contain !important;
  aspect-ratio: 1/1;
  background-position: center;
}

.veggies-section .veggies .image-main .electronic-img {
  width: 117px;
  height: auto;
  object-fit: contain;
  aspect-ratio: 1/1;
}

.veggies-section .veggies {
  border-radius: 0px;
  width: 100%;
  height: 100%;
  background: #F6FAFF;
  box-shadow: 0px 1px 5px #ffffff00;
  opacity: 1;
  transition: width 0.5s ease, height 0.5s ease, background 0.5s ease;
  border: 0.5px solid transparent;
}
