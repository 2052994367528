.select-categories {
  margin-top: 2%;
  margin-bottom: 12%;
}
.select-categories .nav {
  overflow: hidden;
}

.select-categories .nav-pills .nav-link.active {
  background: transparent;
}

.select-categories .view-all {
  margin-top: 2%;
}

.select-categories .product-image {
  position: relative;
  overflow: hidden;
  border-radius: 5px;
}

.select-categories .product-image .category-image {
  width: 200px;
  height: 128px;
  border-radius: 5px;
  transition: 0.3s;
}

.select-categories .product-image:hover .category-image {
  transform: scale(1.1);
  transition: 0.3s;
}

.select-categories .product-image .text-holder {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  text-align: center;
  width: 100%;
  /* Ensure the entire width is centered */
}

.select-categories .product-image .text-holder p {
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;
  margin-bottom: 4%;
}

.select-categories .product-image .bg-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: transparent linear-gradient(180deg,
      #0f376800 0%,
      #0d336020 29%,
      #031a36d2 84%,
      #00142b 100%) 0% 0% no-repeat padding-box;
  border-radius: 5px;
}

.select-categories .product-slide {
  width: auto !important;
}

.select-categories .mySwiper1 {
  margin-left: 0%;
}

.select-categories .nav-pills .nav-link.active {
  background: transparent;
  border-radius: 0%;
  position: relative;
}

.select-categories .nav-pills .nav-link {
  padding-left: 10px;
  padding-right: 0px;
  margin-bottom: 20px;
}

.select-categories .nav-pills .nav-link.active .triangle-right {
  display: block !important;
  /* border-top: 7px solid transparent;
  border-left: 13px solid #f99a1a;
  border-bottom: 7px solid transparent; */
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 8px solid transparent;
  border-top: 12px solid #f99a1a;
  position: absolute;
  left: 47%;
  bottom: -8%;
  z-index: 99999;
  /* transform: rotate(91deg); */
}

.select-categories .nav-pills .nav-link .triangle-right {
  display: none;
  z-index: 99999;
}

.select-categories .select-categories-slider {
  padding: 10px 0px;
}

.select-categories .desktop_view_product_category_slider {
  display: block;
}

.select-categories .mobile_view_product_category_slider {
  display: none;
}

@media (min-width: 0px) and (max-width: 991px) {
  .select-categories .product-image .text-holder p {
    font-size: 14px;
  }

  .select-categories .desktop_view_product_category_slider {
    display: none;
  }

  .select-categories .mobile_view_product_category_slider {
    display: block;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .select-categories {
    margin-bottom: 18%;
  }
}

@media (min-width: 578px) and (max-width: 767px) {
  .select-categories {
    margin-bottom: 20%;
  }
}

@media (min-width: 425px) and (max-width: 577px) {
  .select-categories {
    margin-bottom: 30%;
  }
}

@media (min-width: 0px) and (max-width: 420px) {
  .select-categories {
    margin-bottom: 35%;
  }
}