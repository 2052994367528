@import url("https://fonts.googleapis.com/css2?family=Italianno&family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Raleway:ital,wght@0,100..900;1,100..900&family=Roboto+Mono:ital,wght@0,100..700;1,100..700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

body {
  margin: 0px;
  padding: 0px;
  font-family: "Roboto" !important;
}

p,
a,
span,
img,
svg,
h1,
h2,
h3,
h4,
h5,
h6,
button ,
div {
  cursor: pointer !important;
}

.title {
  font-family: "Roboto" !important;
  font-size: 30px;
  font-weight: bold;
  color: #333333;
}

.local-text {
  font-size: 16px;
}

.product-text {
  font-size: 15px;
  font-family: "Roboto" !important;
  text-align: center;
  font-weight: 500;
  margin-bottom: 0px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;
}



.view-all p {
  font-size: 14px;
  color: #0f3768;
  text-align: end;
  font-weight: 500;
  text-decoration: underline;
  cursor: pointer;
}

.view-all p:hover {
  font-size: 14px;
  color: #df4223;
  text-align: end;
  text-decoration: underline;
  cursor: pointer;
}

.frequently {
  font-size: 14px;
  font-weight: 600;
  color: #333333;
  font-family: "Roboto" !important;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 1;
}

.weight {
  font-weight: 500;
  font-size: 11px;
  color: #333333;
  font-family: "Roboto" !important;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 1;
}

.weight2 {
  font-weight: 500;
  font-size: 12px;
  text-decoration: line-through;
}

.product-detail {
  font-size: 13px;
  color: #0f3768;
  font-family: "Roboto" !important;
  font-weight: 500;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 1;
}





@media (min-width: 0px) and (max-width: 485px) {
  .title {
    font-size: 20px;
  }

  .local-text {
    font-size: 14px;
  }

  .product-text {
    font-size: 15px;
  }
}

@media (min-width: 486px) and (max-width: 575px) {
  .title {
    font-size: 22px;
  }

  .product-text {
    font-size: 13px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .title {
    font-size: 24px;
  }

  .product-text {
    font-size: 13px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .title {
    font-size: 26px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .title {
    font-size: 28px;
  }
}

@media (min-width: 767px) and (max-width: 1400px) {
  .product-text {
    font-size: 14px;
  }
}