.near-mart-store .online-store {
  background: #fff0ee;
  border-radius: 5px;
  height: 269px;
}

.online-store h2 {
  font-size: 20px;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.online-store .store-holder {
  background-color: #fff;
  width: 92px;
  height: 92px;
  border-radius: 50px;
  margin: auto;
}

.online-store .store-holder .store-img {
  width: 92px;
  height: 92px;
}

svg.star-svg {
  width: 17px;
  height: 18px;
}

.location-holder .location-icon {
  width: 9px;
  height: 13px;
}

.location-holder span {
  font-size: 12px;
  font-weight: 500;
}

.online-store p {
  background: #f99a1a 0% 0% no-repeat padding-box;
  border-radius: 5px 5px 0px 0px;
  color: #fff;
  font-size: 10px;
  padding: 2px;
  margin: 23px 37px 0px 37px;
}