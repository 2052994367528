.about-banner {
    width: 100%;
    height: 100%;
    border-radius: 25px;
    /* margin-bottom: 4%; */
}

@media(min-width:0px) and (max-width:767px) {
    .about-banner {
        height: 150px;
        border-radius: 20px;

    }
}