.second-header-sec {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 1024;
}

.second-header-sec .headlogo {
  width: 120px;
  height: 63px;
  object-fit: contain;
}

.second-header-sec {
  background: #FFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
}

.second-header-sec .navbar-nav .nav-link {
  color: #000;
  font-size: 15px;
  font-weight: 500;
  padding-bottom: 15px;
  margin: 25px 11px 0px 11px;
}

.second-header-sec .navbar-nav .nav-link:hover {
  color: #000;
  font-size: 15px;
  font-weight: 500;
  padding-bottom: 15px;
  margin: 25px 11px 0px 11px;
}

.second-header-sec .navbar-nav .nav-link.active {
  color: #000;
  font-size: 15px;
  font-weight: 500;
  margin: 25px 11px 0px 11px;
  padding-bottom: 15px;
  border-bottom: 2px solid #f99a1a;
}

.second-header-sec .navbar-nav .nav-link.show {
  color: #000;
  font-size: 15px;
  font-weight: 500;
  margin: 25px 11px 0px 11px;
  padding-bottom: 15px;
}

/* .second-header-sec .navbar-nav .dropdown {
  background: #df4223 0% 0% no-repeat padding-box;
  width: 352px;
} */

.second-header-sec .navbar {
  padding-top: 0px;
  padding-bottom: 0px;
}

.second-header-sec .dropdown-toggle.show::after {
  font-size: 20px;
  vertical-align: 0;
  transform: rotate(180deg);
}

.second-header-sec .dropdown-toggle::after {
  font-size: 20px;
  vertical-align: 0;
}

.second-header-sec .dropdown-toggle::after {
  position: absolute;
  right: -7px;
  margin-top: 6px;
}

.second-header-sec .cart-logo {
  color: #000;
  margin: 10px 0px 0px 26px;
  cursor: pointer;
}

.second-header-sec .navbar-toggler:focus {
  box-shadow: none;
}

.second-header-sec .dropdown-menu {
  background-color: #000;
  border-radius: 0%;
  width: 352px;
  padding-top: 0%;
  padding-bottom: 0%;
  z-index: 1;
}

.second-header-sec .dropdown-item:hover {
  background: #f99a1a 0% 0% no-repeat padding-box;
  color: #FFF;
  font-size: 15px;
  padding: 10px;
}

.second-header-sec .dropdown-item:active {
  background: #f99a1a 0% 0% no-repeat padding-box;
  color: #FFF;
  font-size: 15px;
  padding: 10px;
}

.second-header-sec .dropdown-item {
  color: #FFF;
  font-size: 15px;
  padding: 10px;
}

.second-header-sec .login {
  font-size: 18px;
  color: #000;
  padding-left: 16px;
  margin-top: 6px;
}

.second-header-sec .dropdown-menu[data-bs-popper] {
  top: 99%;
  left: 0;
  margin-top: 0;
}



.second-header-sec .all-icon {
  position: relative;
}

.second-header-sec .input-group-box {
  position: absolute;
  top: 51px;
  width: 425px;
  z-index: 1;
  left: -163%;
}

.second-header-sec .input-group-box .form-control {
  border-radius: 0%;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 0.5px solid #707070;
  border-radius: 19px;
  font-size: 13px;
}

@media (min-width: 1200px) and (max-width: 1400px) {
  .second-header-sec .navbar-nav .nav-link {
    color: #000;
    font-size: 14px;
    margin: 25px 11px 0px 11px;
  }

  .second-header-sec .navbar-nav .nav-link:hover {
    color: #000;
    font-size: 14px;
    margin: 25px 11px 0px 11px;
  }

  .second-header-sec .navbar-nav .nav-link.active {
    color: #000;
    font-size: 14px;
    margin: 25px 11px 0px 11px;
  }

  .second-header-sec .navbar-nav .nav-link.show {
    color: #000;
    font-size: 14px;
    margin: 25px 11px 0px 11px;
  }

  .second-header-sec .navbar-expand-lg .navbar-nav .nav-link {
    padding-left: 0px;
    padding-right: 0%;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .second-header-sec .navbar-nav .nav-link {
    color: #000;
    font-size: 13px;
    margin: 25px 7px 0px 7px;
  }

  .second-header-sec .navbar-nav .nav-link:hover {
    color: #000;
    font-size: 13px;
    margin: 25px 7px 0px 7px;
  }

  .second-header-sec .navbar-nav .nav-link.active {
    color: #000;
    font-size: 13px;
    margin: 25px 7px 0px 7px;
  }

  .second-header-sec .navbar-nav .nav-link.show {
    color: #000;
    font-size: 13px;
    margin: 25px 7px 0px 7px;
  }

  .second-header-sec .navbar-expand-lg .navbar-nav .nav-link {
    padding-left: 0px;
    padding-right: 0%;
  }

  .second-header-sec .login {
    margin-top: 14px;
    font-size: 13px;
  }

  /* .second-header-sec .navbar-nav .dropdown {
    width: 299px;
  } */
  .second-header-sec .dropdown-toggle::after {
    position: absolute;
    right: -8px;
    margin-top: 7px;
  }

  .second-header-sec .dropdown-menu {
    width: 299px;
  }

  .second-header-sec .cart-logo {
    color: #000;
    margin: 18px 0px 0px 17px;
  }
}

@media (min-width: 0px) and (max-width: 991px) {
  .second-header-sec .desktop-view {
    display: none;
  }

  .second-header-sec .login {
    font-size: 13px;
    color: #000;
    padding-top: 0px;
    margin-top: 2px;

  }

  .second-header-sec .mobile-view {
    display: block;
  }

  .second-header-sec .navbar-toggler {
    color: #000;
    background-color: #fff !important;
    margin-top: 8px;
    padding: 2px 10px;
  }

  .second-header-sec .navbar-nav .dropdown {
    margin-top: 10px;
  }

  .second-header-sec .navbar-nav .nav-link {
    color: #000;
    font-size: 13px;
    margin: 4px 16px 0px 10px;
  }

  .second-header-sec .navbar-nav .nav-link:hover {
    color: #000;
    font-size: 13px;
    margin: 4px 16px 0px 10px;
  }

  .second-header-sec .navbar-nav .nav-link.active {
    color: #000;
    font-size: 13px;
    border-bottom: none;
    margin: 4px 16px 0px 10px;
  }

  .second-header-sec .navbar-nav .nav-link.show {
    color: #000;
    font-size: 13px;
    border-bottom: none;
    margin: 4px 16px 0px 10px;
  }

  /* .second-header-sec .navbar-nav .dropdown {
    width: 298px;
  } */
  .second-header-sec .dropdown-toggle::after {
    position: absolute;
    right: inherit;
    margin-top: 6px;
  }

  .second-header-sec .dropdown-menu {
    width: 298px;
  }

  .second-header-sec .dropdown-item:hover {
    background: #df4223 0% 0% no-repeat padding-box;
    color: #FFF;
    font-size: 12px;
    padding: 10px;
  }

  .second-header-sec .dropdown-item:active {
    background: #df4223 0% 0% no-repeat padding-box;
    color: #FFF;
    font-size: 12px;
    padding: 10px;
  }

  .second-header-sec .dropdown-item {
    color: #FFF;
    font-size: 12px;
    padding: 10px;
  }

  .top-header .search .form-control {
    font-size: 13px;
  }

  .top-header .search .search-btn {
    font-size: 13px;
    width: 84px;
  }

  .top-header .headlogo {
    width: 96px;
    height: auto;
  }

  .second-header-sec .cart-logo {
    margin: 0px 10px;
  }

  .top-header {
    background-color: #fff;
    padding: 10px 0px;
  }

  .top-header .search {
    margin-top: 15px;
  }

  .second-header-sec .input-group-box {
    position: inherit;
    width: 100%;
    left: 0px;
    top: 8px;
  }
}