.online-store .distance {
    background: #df4223 0% 0% no-repeat padding-box;
    border-radius: 5px 5px 0px 0px;
    color: #fff;
    font-size: 10px;
    padding: 5px;
    /* margin: 23px 37px 0px 37px; */
    width: 175px;
    text-align: center;
}

.near-mart-store .online-store-2 {
    background: #fff0ee;
    border-radius: 5px;
    height: 162px;
}

@media(min-width:0px) and (max-width:767px) {
    .near-mart-store .online-store-2 {
        background: #fff0ee;
        border-radius: 5px;
        height: 269px;
    }
}