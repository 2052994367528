/* Popular Categories css Started */

.Popular-Categories {
  margin: 5% 0;
}

.Popular-Categories .main-section {
  position: relative;
  transition: transform 0.4s ease-in-out;
  border-radius: 10px;
}

.Popular-Categories .main-section:hover {
  transform: translateY(-15px);
}

.Popular-Categories .main-section .products {
  height: 100%;
  border-radius: 10px;
  width: 100%;
  aspect-ratio: 4/4;
  object-fit: cover;
  object-position: top;
}

.Popular-Categories .main-section .bg-overlay {
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 60%;
  background: transparent linear-gradient(180deg, #0F376800 0%, #0D336020 29%, #031A36D2 84%, #00142B 100%) 0% 0% no-repeat padding-box;
  border-radius: 10px;
}

.Popular-Categories .main-section .text-holder {
  position: absolute;
  bottom: 0px;
  left: 19%;
  transform: translate(-21%, -50%);
  z-index: 1;
  width: 100%;
}

.Popular-Categories .main-section .text-holder .product-text {
  color: #FFF;
  font-size: 20px;
  font-weight: 500;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;
}

.Popular-Categories .silder-btn {
  position: relative;
}

.Popular-Categories .silder-btn .back-btn {
  content: url('../../../../public/assets/Image/Homepage/Popular-Categories/left.png');
  width: 24px;
  height: 24px;
  /* margin: 10px; */
  cursor: pointer;
  position: absolute;
  bottom: 205px;
  z-index: 1;
  left: -17px;
  filter: brightness(0%);
}

.Popular-Categories .silder-btn .next-btn {
  content: url('../../../../public/assets/Image/Homepage/Popular-Categories/right.png');
  width: 24px;
  height: 24px;
  /* margin: 10px; */
  cursor: pointer;
  position: absolute;
  bottom: 205px;
  z-index: 1;
  right: -17px;
  filter: brightness(0%);
}

.Popular-Categories .swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
  display: none;
}


/* 
.Popular-Categories .swiper-pagination-bullet-active {
  opacity: var(--swiper-pagination-bullet-opacity, 1);
  background: #df4223;
}

.Popular-Categories .swiper-pagination-bullet {
  background: #df4223;
  opacity: 1;
} */



/* Popular Categories css End */

/* Media Query PopularCategories Started */
@media(min-width:0px) and (max-width:420px) {
  .Popular-Categories .main-section .text-holder {
    left: 20%;
    transform: translate(-21%, -48%);
    width: 100%;
  }

  .Popular-Categories .main-section .text-holder .product-text {
    font-size: 12px !important;
  }
}

@media(min-width:0px) and (max-width:767px) {
  .Popular-Categories .silder-btn .back-btn {
    width: 20px;
    height: 20px;
    left: -8px;
  }

  .Popular-Categories .silder-btn .next-btn {
    width: 20px;
    height: 20px;
    right: -9px;
  }

  .Popular-Categories .main-section .text-holder .product-text {
    font-size: 14px;
  }

}

@media(min-width:768px) and (max-width:991px) {
  .Popular-Categories .main-section .text-holder .product-text {
    font-size: 16px;
  }
}