/* Empty_Cart css start */
.Empty_Cart {
  margin-top: 8%;
  margin-bottom: 12%;
}

.Empty_Cart .main-btn-div {
  text-align: center;
  margin-top: 19%;
}

.Empty_Cart .main-btn-div .back-btn {
  background: #dedede 0% 0% no-repeat padding-box;
  border-radius: 5px;
  border: 1px solid #dedede;
  text-align: left;
  font-size: 14px;
  font-weight: 500;
  color: #000;
  padding: 9px 29px;
  /* margin: 0px 5px; */
}

.Empty_Cart .heading_holder h5 {
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  color: #000000;
}

.Empty_Cart .heading_holder p {
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  color: #464646;
}

.Empty_Cart .img-holder {
  text-align: center;
  margin-bottom: 1%;
}

.Empty_Cart .img-holder .basket_img {
  width: 60px;
  height: 60px;
}

@media(min-width:0px) and (max-width:321px) {
  .Empty_Cart {
    margin-bottom: 40%;
  }
}

@media(min-width:321px) and (max-width:486px) {
  .Empty_Cart {
    margin-bottom: 35%;
  }
}

@media(min-width:486px) and (max-width:567px) {
  .Empty_Cart {
    margin-bottom: 30%;
  }
}

@media(min-width:568px) and (max-width:767px) {
  .Empty_Cart {
    margin-bottom: 25%;
  }
}

@media(min-width:768px) and (max-width:992px) {
  .Empty_Cart {
    margin-bottom: 20%;
  }
}

@media(min-width:992px) and (max-width:1200px) {
  .Empty_Cart {
    margin-bottom: 15%;
  }
}

/* Empty_Cart css end */