.wishlist {
  margin-top: 2%;
  margin-bottom: 12%;
}

.wishlist .breadcrumb-item+.breadcrumb-item::before {
  content: url("../../../public/assets/Image/Icon/arrow-forward.png");
}

.wishlist .breadcrumb-item {
  font-size: 13px;
  font-weight: 400;
  cursor: pointer;
  color: #000;
}

.wishlist .breadcrumb-item.active {
  color: #000;
}

.wishlist a {
  font-size: 13px;
  font-weight: 600;

  color: #0f3768;
}

.wishlist .view-all-product p {
  font-size: 16px;
  font-weight: 600;
  color: #000;
  text-decoration: none;
  text-align: end;
}

@media(min-width:0px) and (max-width:321px) {
  .wishlist {
    margin-bottom: 40%;
  }
}

@media(min-width:321px) and (max-width:486px) {
  .wishlist {
    margin-bottom: 35%;
  }
}

@media(min-width:486px) and (max-width:567px) {
  .wishlist {
    margin-bottom: 30%;
  }
}

@media(min-width:568px) and (max-width:767px) {
  .wishlist {
    margin-bottom: 25%;
  }
}

@media(min-width:768px) and (max-width:992px) {
  .wishlist {
    margin-bottom: 20%;
  }
}

@media(min-width:992px) and (max-width:1200px) {
  .wishlist {
    margin-bottom: 15%;
  }
}