.main-product-img-main .card-second-line {
  font-size: 15px;
  color: #f99a1a;
  text-align: left;
  margin-bottom: 5px;
  font-weight: 500;
  margin-top: 5%;
}

.main-product-img-main {
  text-align: right;
  height: auto;
}



.main-product-img-main .card {
  border: none;
  /* box-shadow: 0px 3px 3px #00000029; */
  background: #f6f6f6;
}

.main-product-img-main .text-holder {
  text-align: left;
}

.main-product-img-main .text-holder h4 {
  text-align: left;
  font-size: 14px;
  font-weight: 600;
  color: #333333;
  margin-bottom: 0px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 1;
}

.main-product-img-main .text-holder span {
  text-align: left;
  font-size: 13px;
  font-weight: 400;
  color: #333333;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 1;
}

.main-product-img-main .card .card-body {
  background: #fff;
  /* padding-top: 0px; */
}

.main-product-img-main {
  overflow: hidden;
  width: 100%;
  box-sizing: border-box;
  display: block;
  box-shadow: 0px 3px 3px #00000029;
  border-radius: 5px;
}

.main-product-img-main:hover {
  overflow: hidden;
  width: 100%;
  box-sizing: border-box;
  display: block;
  box-shadow: 0px 0px 3px 1px #df42236b;
  border-radius: 5px;
}

.main-product-img-main .main-product-main {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: contain !important;
  aspect-ratio: 1/1;
  background-position: center;
  overflow: hidden;
}

.main-product-img-main .main-product-main .product-img {
  width: 100%;
  height: auto;
  object-fit: contain;
  aspect-ratio: 1/1;
  transition: transform 0.5s;
}

.main-product-img-main:hover .product-img {
  transform: scale(1.3);
}

.main-product-img-main .card-footer {
  border-top: none;
  background-color: #fff;
}



.main-product-img-main .add-cart-sec .cart-logo.active {
  color: #df4223;
}

.main-product-img-main .add-cart-sec .cart-logo {
  color: #000;
}

.main-product-img-main .add-cart-sec p {
  font-size: 12px;
  color: #000;
  font-weight: 500;
  margin-bottom: 0px;
  cursor: pointer;
}

.main-product-img-main .add-cart-sec {
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding: 5px 5px;
}

@media (min-width: 992px) and (max-width: 1200px) {
  .main-product-img-main .card-second-line {
    font-size: 14px;
  }
}

@media (min-width: 1200px) and (max-width: 1400px) {
  .main-product-img-main:hover .add-cart-sec {
    bottom: 100px;
    left: 99px;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .main-product-img-main:hover .add-cart-sec {
    left: 135px;
  }
}

@media (min-width: 0px) and (max-width: 767px) {
  .main-product-img-main:hover .orange-bg-card {
    left: 40%;
  }
}