.my-account {
  margin-top: 2%;
  margin-bottom: 12%;
}

.my-account .title {
  font-size: 18px;
  font-weight: 600;
  background-color: #f2f2f2;
  padding: 15px 20px;
  border-radius: 5px;
  text-align: left;
  color: #323232;
}

.my-account .breadcrumb-item+.breadcrumb-item::before {
  content: url("../../../../../public/assets/Image/Icon/arrow-forward.png");
}

.my-account .breadcrumb-item {
  font-size: 13px;
  font-weight: 400;
  cursor: pointer;
  color: #000;
}


.my-account .breadcrumb-item.active a {
  font-size: 13px;
  font-weight: 400;

  color: #000;
}

.my-account .breadcrumb-item a {
  font-size: 13px;
  font-weight: 600;

  color: #0f3768;
}


.my-account .product-section-div {
  border: 1px solid #c9bfbf;
  padding: 18px;
  border-radius: 5px;
  margin-top: 20px;
}

.my-account .product-section-div .date {
  font-size: 14px;
  color: #000000;
  margin-bottom: 5px;
  text-align: left;
  font-weight: 400;
  color: #000000;
}

.my-account .product-section-div .price {
  font-size: 18px;
  font-weight: 600;
}

.my-account .product-section-div .items {
  font-size: 18px;
  font-weight: 600;
  text-align: left;
  color: #000000;
}

.my-account .product-section-div .product-div .product {
  width: 100%;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 6px;
}

.my-account .product-section-div .product-div .product .img-set {
  width: 100%;
  height: 90px;
  border: 0.5px solid #707070;
  border-radius: 5px;
}

.my-account .product-div-li {
  list-style: none;
  margin: 10px;
}

.my-account .product-div-ul {
  display: flex;
  padding: 0px;
}

.my-account .stepper-div .order {
  font-size: 16px;
  font-weight: 500;
  margin: 20px 0px;
  text-align: left;
  color: #000000;
}

.my-account .product-buttons .buy,
.my-account .product-buttons .buy:hover {
  background-color: #000000;
  font-size: 14px;
  font-weight: 400;
  padding: 7px 50px;
  color: #ffffff;
  border: none;
  border-radius: 5px;
}

.my-account .product-buttons .cancel,
.my-account .product-buttons .cancel:hover {
  color: #fff;
  background-color: #f99a1a;
  font-size: 14px;
  font-weight: 400;
  padding: 7px 50px;
  margin-left: 10px;
  border: none;
  border-radius: 5px;
}

.my-account .product-buttons {
  text-align: end;
  margin-top: 60px;
}

.my-account .order-box {
  box-shadow: 0px 0px 5px 0px #ccc;
  padding: 20px;
  border-radius: 6px;
}

.my-account .order-fafa-circle {
  width: 60px;
  height: 60px;
  border: 1px solid #000000;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.my-account .order-fafa-img {
  width: 50%;
}

.my-account .order-text-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.my-account .order-text-div .order-p {
  font-size: 14px;
  font-weight: 500;
  color: #000000;
  margin-bottom: 0px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.my-account a {
  text-decoration: none;
}

.my-account .order-text-div .align-fafa {
  float: right;
}

.my-account .stepper-div .form-range {
  width: 40%;
}

.my-account .range-text-div {
  /* margin: 0px 95px 0px 0px; */
  position: absolute;
  top: 27px;
  left: 0;
  /* right: 0; */
  width: 100px;
  text-align: center;
}

.my-account .range-text-div .placed {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0px;
  text-align: left;
  color: #000000;
}

.my-account .range-text-div .date {
  font-size: 12px;
  margin-bottom: 0px;
  font-weight: 400;
}

.my-account input[type="range"] {
  -webkit-appearance: none;
}

.my-account input[type="range"]::-webkit-slider-runnable-track {
  width: 300px;
  height: 5px;
  background: #f05340;
  border: none;
  border-radius: 3px;
}

.my-account input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: 3px solid #f05340;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: #fff;
}

.my-account input[type="range"]:focus {
  outline: none;
}

.my-account input[type="range"]:focus::-webkit-slider-runnable-track {
  background: #f05340;
}

.stepper-div .RSPBprogressBar {
  height: 5px;
  width: 50%;
}

.stepper-div .RSPBprogressBar .indexedStep.null {
  width: 15px;
  height: 15px;
}

.stepper-div .RSPBprogressBar .RSPBprogression {
  position: absolute;
  transition: width 0.3s ease;
  left: 0;
  top: 0;
  bottom: 0;
  border-radius: 10px;
  background: rgb(240 83 64);
  z-index: -1;
}

.stepper-div .indexedStep.accomplished {
  background-color: #df4223;
  width: 15px;
  height: 15px;
}

.stepper-div .indexedStep {
  position: relative;
  color: white;
  width: 15px;
  height: 15px;
  font-size: 12px;
  background-color: rgba(211, 211, 211, 0.8);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 319px) {
  .my-account .stepper-div .form-range {
    width: 85%;
  }

  .my-account .product-section-div .product-div .product .img-set {
    height: 60px;
  }

  .my-account .product-buttons .buy,
  .my-account .product-buttons .buy:hover {
    font-size: 12px;
    padding: 8px 15px;
  }

  .my-account .product-buttons .cancel,
  .my-account .product-buttons .cancel:hover {
    font-size: 12px;
    padding: 8px 15px;
  }

  .my-account .title {
    font-size: 16px;
    padding: 10px;
  }

  .my-account .order-fafa-circle {
    width: 50px;
    height: 50px;
  }

  .my-account .order-text-div .order-p {
    font-size: 12px;
  }

  .my-account .product-section-div .items {
    font-size: 11px;
  }

  .product-section-div .price {
    font-size: 16px;
  }

  .my-account .product-section-div .price {
    font-size: 11px;
  }

  .my-account .product-section-div .date {
    font-size: 10px;
  }

  .my-account .stepper-div .order {
    font-size: 16px;
    margin: 10px 0px;
  }

  .my-account .range-text-div .placed {
    font-size: 10px;
  }

  .stepper-div .RSPBprogressBar {
    width: 100%;
  }

  .my-account .range-text-div {
    top: -82px;
    left: -43px;
    transform: rotateZ(271deg);
  }

  .stepper-div .RSPBprogressBar {
    width: 76%;
    transform: rotate(90deg);
  }

  .stepper-div {
    margin-top: 49%;
    margin-bottom: 48%;

  }

  .my-account .product-buttons {
    margin-top: 100px;
  }

  .my-account {
    margin-bottom: 40%;
  }
}

@media (min-width: 320px) and (max-width: 484px) {
  .my-account .stepper-div .form-range {
    width: 70%;
  }

  .my-account .product-section-div .product-div .product .img-set {
    height: 84px;
  }

  .my-account .product-buttons .buy,
  .my-account .product-buttons .buy:hover {
    font-size: 12px;
    padding: 8px 15px;
  }

  .my-account .product-buttons .cancel,
  .my-account .product-buttons .cancel:hover {
    font-size: 12px;
    padding: 8px 15px;
  }

  .my-account .title {
    font-size: 16px;
    padding: 10px;
  }

  .my-account .order-fafa-circle {
    width: 50px;
    height: 50px;
  }

  .my-account .order-text-div .order-p {
    font-size: 12px;
  }

  .my-account .product-section-div .items {
    font-size: 16px;
  }

  .my-account .product-section-div .price {
    font-size: 16px;
  }

  .my-account .product-section-div .date {
    font-size: 11px;
  }

  .my-account .stepper-div .order {
    font-size: 16px;
    margin: 10px 0px;
  }

  .my-account .range-text-div .placed {
    font-size: 11px;
  }

  .stepper-div .RSPBprogressBar {
    width: 90%;
  }

  .my-account .range-text-div {
    top: -82px;
    left: -43px;
    transform: rotateZ(271deg);
  }

  .stepper-div .RSPBprogressBar {
    width: 76%;
    transform: rotate(90deg);
  }

  .stepper-div {
    margin-top: 49%;
    margin-bottom: 48%;

  }

  .my-account .product-buttons {
    margin-top: 100px;
  }

  .my-account {
    margin-bottom: 35%;
  }
}

@media (min-width: 485px) and (max-width: 574px) {
  .my-account .product-section-div .product-div .product .img-set {
    height: 115px;
  }

  .my-account .stepper-div .form-range {
    width: 80%;
  }

  .my-account .product-buttons .buy,
  .my-account .product-buttons .buy:hover {
    font-size: 12px;
    padding: 8px 15px;
  }

  .my-account .product-buttons .cancel,
  .my-account .product-buttons .cancel:hover {
    font-size: 12px;
    padding: 8px 15px;
  }

  .my-account .my-account .title {
    font-size: 16px;
    padding: 10px;
  }

  .my-account .order-text-div .order-p {
    font-size: 14px;
  }

  .my-account .product-section-div .items {
    font-size: 16px;
  }

  .my-account .product-section-div .price {
    font-size: 16px;
  }

  .my-account .product-section-div .date {
    font-size: 12px;
  }

  .my-account .stepper-div .order {
    font-size: 16px;
    margin: 10px 0px;
  }

  .my-account .range-text-div .placed {
    font-size: 12px;
  }

  .stepper-div .RSPBprogressBar {
    width: 90%;
  }

  .my-account {
    margin-bottom: 30%;
  }
}

@media (min-width: 575px) and (max-width: 767px) {
  .stepper-div .RSPBprogressBar {
    width: 90%;
  }

  .my-account .stepper-div .form-range {
    width: 75%;
  }

  .my-account .product-buttons .buy,
  .my-account .product-buttons .buy:hover {
    font-size: 12px;
    padding: 8px 25px;
  }

  .my-account .product-buttons .cancel,
  .my-account .product-buttons .cancel:hover {
    font-size: 12px;
    padding: 8px 25px;
  }

  .my-account {
    margin-bottom: 30%;
  }

  /* .product-section-div .product-div .product .img-set {
        width: 60%;
    } */
}

@media (min-width: 768px) and (max-width: 991px) {
  .stepper-div .RSPBprogressBar {
    width: 90%;
  }

  .my-account .stepper-div .form-range {
    width: 63%;
  }

  .my-account .order-fafa-circle {
    width: 65px;
    height: 65px;
  }

  .my-account .order-text-div .order-p {
    font-size: 16px;
  }

  .my-account {
    margin-bottom: 20%;
  }
}

@media (min-width: 992px) and (max-width: 1023px) {
  .my-account .range-text-div {
    margin: 0px 50px 0px 0px;
  }

  .my-account .order-fafa-circle {
    width: 65px;
    height: 65px;
  }

  .my-account .order-text-div .order-p {
    font-size: 16px;
  }

  .my-account {
    margin-bottom: 15%;
  }
}

@media (min-width: 1024px) and (max-width: 1199px) {
  .my-account .range-text-div {
    margin: 0px 50px 0px 0px;
  }

  .my-account .order-fafa-circle {
    width: 45px;
    height: 45px;
  }

  .my-account .order-text-div .order-p {
    font-size: 16px;
  }

  .my-account {
    margin-bottom: 15%;
  }
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .my-account .range-text-div {
    margin: 0px 70px 0px 0px;
  }
}