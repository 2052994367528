.Sub_category_sec {
    margin: 5% 0% 10% 0%;
}

.Sub_category_sec .catlistdiv {
    background: aliceblue;
}

.Sub_category_sec .catlistdiv .nav-pills .nav-link {
    color: #000;
    background-color: transparent;
    font-size: 14px;
}

.Sub_category_sec .catlistdiv .nav-pills .nav-link:hover {
    background-color: #ffd8a3;
}

.Sub_category_sec .catlistdiv .nav-pills .nav-link.active {
    color: #fff;
    background-color: #f99a1a;
}

.Sub_category_sec .catlistdiv .catimg {
    width: 40px;
    height: 30px;
    border-radius: 5px;
    object-fit: cover;
    margin-right: 7px;
}

.Sub_category_sec .catlistdiv {
    height: 400px;
    overflow-y: scroll;
    position: sticky;
    top: 100px;
}

.Sub_category_sec .child-prod-list .subcatdiv .nav-link {
    color: #000;
    font-size: 14px;
    width: max-content;
}

.Sub_category_sec .child-prod-list .subcatdiv .nav {
    display: inline-flex;
    width: 100%;
    overflow-x: scroll;
    flex-wrap: nowrap;
}


.Sub_category_sec .child-prod-list .subcatdiv .nav-link.active {
    background-color: #f99a1a;
    color: #fff;
}

.Sub_category_sec .catlistdiv .nav {
    /* flex-direction: column; */
    display: block;
}

.Sub_category_sec .sub_cat_main_div {
    margin-bottom: 50px;
}

@media (min-width:0px) and (max-width:767.98px) {

    .Sub_category_sec {
        margin: 5% 0% 21% 0%;
    }

    .Sub_category_sec .catlistdiv .nav {
        flex-direction: row;
        display: inline-flex;
        /* width: 100%; */
        overflow-x: scroll;
        flex-wrap: nowrap;
    }

    .Sub_category_sec .catlistdiv .nav-pills .nav-link {
        font-size: 13px;
        width: max-content;
        padding: 5px 10px;
    }

    .Sub_category_sec .catlistdiv .catimg {
        width: 25px;
        height: 25px;
        border-radius: 5px;
        object-fit: cover;
        margin-right: 7px;
    }

    .Sub_category_sec .catlistdiv {
        padding: 10px;
        height: auto;
        overflow-y: scroll;
        position: sticky;
        top: 100px;
        margin-bottom: 20px;
    }

    .main-product-img-main .add-cart-sec p span {
        display: none;
    }

    .main-product-img-main .card .card-body {
        padding: 0px 7px;
    }

    .main-product-img-main .text-holder h4 {
        font-size: 12px;
    }

    .main-product-img-main .text-holder span {
        font-size: 12px;
    }

    .main-product-img-main .card-second-line {
        font-size: 12px;
        margin-top: 1%;
    }

    .Sub_category_sec .mobstickyy {
        position: sticky;
        top: 40px;
        z-index: 99;
        width: 100%;
        padding: 0px !important;
    }

    .Sub_category_sec .allcal {
        text-align: center;
    }
}

@media (min-width:768px) and (max-width:1200px) {
    .Sub_category_sec {
        margin: 5% 0% 17% 0%;
    }
}