.footer {
    background: #000 0% 0% no-repeat padding-box;
    padding-top: 79px;

}

.footer .headlogo {
    width: 148px;
    height: 79px;
    object-fit: contain;
}

.footer .nav {
    margin-top: 23px;
}

.footer .nav-link {
    color: #FFF;
    font-size: 14px;
    padding-left: 0%;
    /* margin: 0px 11px; */
}

.footer .nav-link:hover {
    color: #FFF;
    font-size: 14px;
    padding-left: 0%;
    /* margin: 0px 11px; */
}

.footer .nav-link.active {
    color: #FFF;
    font-size: 14px;
    padding-left: 0%;
    /* margin: 0px 11px; */
}

.footer .nav-link:focus {
    color: #FFF;
    font-size: 14px;
    padding-left: 0%;
    /* margin: 0px 11px; */
}

.footer a {
    color: #fff;
}

.footer .text-area-loaction {
    margin-top: 20px;
}

.footer p {
    font-size: 12px;
    color: #FFF;
}

.footer .email-box .form-control {
    border-radius: 0%;
    padding: 0px;
    border: none;
    font-size: 15px;
    color: #000;
    background-color: transparent;
    padding-left: 10px;
}

.footer .email-box .form-control::placeholder {
    font-size: 15px;
    color: #000;
}

.footer .email-box .form-control:focus {
    box-shadow: none;
}

.footer .email-box .input-group-text {
    border: none;
    background-color: transparent;
    padding-right: 0px;
}

/* .footer .email-box .input-group {
    padding: 0px;
    background-color: transparent;
    border-bottom: 1px solid #FFF;
} */

/* footer banner css start */

.footer-bannermain {
    position: relative;
}



.footer .address-holder .text-holder {
    margin: 23px 0px;
}

.footer .address-holder .text-holder p {
    font-size: 13px;
    color: #fff;
    margin-bottom: 0%;
}

.footer .address-holder .social-icon {
    margin-left: auto;
    margin-top: 34px;
}

.footer .address-holder .social-icon .sociallogo {
    width: 30px;
    height: 30px;
    margin: 0px 5px;
}

.footer .copyright-holder {
    border-top: 1px solid #fff;
}

.footer .copyright-holder p {
    color: #fff;
    font-size: 13px;
    margin: 25px 0px;
}

/* @media (min-width: 0px) and (max-width: 991px) {
  .footer .email-box {
    margin-top: 40px;
  }

  .footer .address-holder .text-holder {
    margin-bottom: 50px;
  }

  .footer .headlogo {
    width: 106px;
    height: 68px;
  }

  .footer .nav {
    margin-top: 32px;
  }
}

@media (min-width: 0px) and (max-width: 486px) {
  .footer .address-holder .text-holder p {
    font-size: 12px;
  }

  .footer .address-holder .social-icon .sociallogo {
    width: 25px;
    height: 25px;
    margin: 0px 2px;
  }

  .footer .email-box .form-control {
    font-size: 12px;
  }

  .footer .email-box .form-control::placeholder {
    font-size: 12px;
  }

  .footer .nav-link {
    padding-right: 5px;
    padding-left: 5px;
    font-size: 13px;
  }

  .footer .nav-link:hover {
    padding-right: 5px;
    padding-left: 5px;
    font-size: 13px;
  }

  .footer .nav-link.active {
    padding-right: 5px;
    padding-left: 5px;
    font-size: 13px;
  }

  .footer .nav-link:focus {
    padding-right: 5px;
    padding-left: 5px;
    font-size: 13px;
  }

  .footer .email-box {
    margin-top: 23px !important;
  }

  .footer {
    padding-top: 43px;
  }
} */

.footer .location-holder {
    display: flex;
    justify-content: center;
    margin-top: 26px;

}

.footer .location-holder .call-holder span {
    color: #FFF;
    font-size: 13px;
}

.footer .location-holder .call-holder .call-icon {
    color: #FFF;
}

@media(min-width:992px) and (max-width:1200px) {
    .footer .nav-link:hover {
        margin: 0px 4px;
    }

    .footer .nav-link.active {
        margin: 0px 4px;
    }

    .footer .nav-link {
        margin: 0px 4px;
    }

    .footer .nav-link:focus {
        margin: 0px 4px;
    }
}

@media(min-width:768px) and (max-width:991px) {
    .footer .nav-link:hover {
        margin: 0px 4px;
    }

    .footer .nav-link.active {
        margin: 0px 4px;
    }

    .footer .nav-link {
        margin: 0px 4px;
    }

    .footer .nav-link:focus {
        margin: 0px 4px;
    }

    .footer .nav {
        margin-top: 46px;
    }

    .footer .address-holder .social-icon {
        margin-top: 19px;
        margin-bottom: 16px;
    }
}

@media(min-width:0px) and (max-width:767px) {
    .footer .nav-link:hover {
        margin: 0px 0px;
        padding: 0px 6px;
        font-size: 13px;
    }

    .footer .nav-link.active {
        margin: 0px 0px;
        padding: 0px 6px;
        font-size: 13px;
    }

    .footer .nav-link {
        margin: 0px 0px;
        padding: 0px 6px;
        font-size: 13px;
    }

    .footer .nav-link:focus {
        margin: 0px 0px;
        padding: 0px 6px;
        font-size: 13px;
    }

    .footer .nav {
        margin-top: 9px;
        margin-bottom: 19px;
    }

    .footer .address-holder .social-icon {
        margin-top: 19px;
        margin-bottom: 16px;
    }

    .footer .location-holder {
        display: unset;
    }


}

/* subscribe-banner-css start*/
.footer-banner {
    margin-top: -170px;
}

.footer-bannermain {
    position: relative;
    margin-bottom: 38px;
}

.footer-bannermain .ban-contentmain {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.footer-bannermain .banconts {
    padding-top: 20px;
    padding-left: 70px;
    padding-right: 10px;
}

.footer-bannermain .banconts .newssetl {
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0px;
    color: #333333;
    opacity: 1;
}

.footer-bannermain .banconts .getss {
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 0px;
    color: #333333;
    opacity: 1;
}

.footer .email-box .input-group {
    padding: 0px 5px;
    background-color: #fff;
    border-radius: 25px;
}

.footer-bannermain .subscribe-btn {
    border-radius: 21px;
    /* height: 38px; */
}

.footer-bannermain .footerimg {
    width: 100%;
    height: 202px;
    border-radius: 10px;
}

@media (min-width: 0px) and (max-width: 991px) {
    .footer-bannermain .banconts {
        padding-top: 15px;
        padding-left: 10px;
    }

    .footer-bannermain .banconts .newssetl {
        font-size: 16px;

    }
}

/* subscribe-banner-css end*/

/* @media(min-width:0px) and (max-width:991px) {
    .footer .email-box {
        margin-top: 40px;
    }

    .footer .address-holder .text-holder {
        margin-bottom: 50px;
    }

    .footer .headlogo {
        width: 106px;
        height: 68px;
    }

    .footer .nav {
        margin-top: 32px;
    }
}

@media(min-width:0px) and (max-width:486px) {
    .footer .address-holder .text-holder p {
        font-size: 12px;
    }

    .footer .address-holder .social-icon .sociallogo {
        width: 25px;
        height: 25px;
        margin: 0px 2px;
    }

    .footer .email-box .form-control {
        font-size: 12px;

    }

    .footer .email-box .form-control::placeholder {
        font-size: 12px;

    }

    .footer .nav-link {
        padding-right: 5px;
        padding-left: 5px;
        font-size: 13px;
    }

    .footer .nav-link:hover {
        padding-right: 5px;
        padding-left: 5px;
        font-size: 13px;
    }

    .footer .nav-link.active {
        padding-right: 5px;
        padding-left: 5px;
        font-size: 13px;
    }

    .footer .nav-link:focus {
        padding-right: 5px;
        padding-left: 5px;
        font-size: 13px;
    }

    .footer .email-box {
        margin-top: 23px !important;
    }

    .footer {
        padding-top: 43px;
    }
} */