.rate-review {
  margin-bottom: 5%;
}

.rate-review .rate-holder {
  background: #dfdddd 0% 0% no-repeat padding-box;
  border-radius: 5px;
  width: 130px;
  height: 38px;
  font-weight: 500;
}

.rate-review .rate-holder p {
  font-size: 14px;
  background: #dfdddd 0% 0% no-repeat padding-box;
  border-radius: 5px;
  margin-top: 8px;
}

.rate-review .rating {
  border-top: 1px solid #a1a1a1;
}

.rate-review .rating p {
  font-size: 14px;
  margin-bottom: 0%;
}

.rate-review .star-icon {
  color: #f6dc00;
  /* font-size: 18px; */
}

.rate-review .rating .plus-btn {
  border-radius: 100%;
  color: #df4223;
  background: #fff;
  border: 1px solid #df4223;
  width: 40px;
  height: 39px;
  margin-top: 19px;
  margin-left: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rate-review .rating .plus-btn input[type="file"] {
  display: none;
}

.rate-review .image-holder {
  display: flex;
  width: 100%;
  overflow: hidden;
  overflow-x: scroll;
  padding: 10px 0px;
}

.rate-review .image-holder .file-img-holder {
  position: relative;
  margin-right: 15px;
}

.rate-review .image-holder::-webkit-scrollbar {
  width: 5px;
}

.rate-review .image-holder::-webkit-scrollbar:horizontal {
  height: 8px;
}

/* Track */
.rate-review .image-holder::-webkit-scrollbar-track {
  background: #e8e8e8;
}

.rate-review .image-holder::-webkit-scrollbar-track:horizontal {
  background: #e8e8e8;
}

/* Handle */
.rate-review .image-holder::-webkit-scrollbar-thumb {
  background: #c7c7c7;
  border-radius: 5px;
}

.rate-review .image-holder::-webkit-scrollbar-thumb:horizontal {
  background: #c7c7c7;
  border-radius: 5px;
}

.rate-review .image-holder .img-logo {
  width: 59px;
  height: 58px;
  /* overflow: hidden; */
}

.rate-review .cross-overlay .img-cross {
  width: 16px;
  height: 16px;
}

.rate-review .cross-overlay {
  position: absolute;
  top: -6px;
  right: -5px;
}

.rate-review .form-holder .form-lable {
  font-size: 14px;
}

.rate-review .form-holder .form-control {
  /* background: #f6f6f6 0% 0% no-repeat padding-box; */
  border-radius: 5px;
  color: #a2a2a2;
  font-size: 14px;
  /* border: none; */
}

.rate-review .form-holder .form-control:focus {
  box-shadow: none;
}

.rate-review .form-holder .button-holder .btn-cancle {
  background: #b7b7b7 0% 0% no-repeat padding-box;
  border: 1px solid #b7b7b7;
  border-radius: 5px;
  color: #fff;
  font-size: 14px;
  width: 107px;
  height: 38px;
}

.rate-review .form-holder .button-holder .btn-post {
  border-radius: 5px;
  color: #fff;
  font-size: 14px;
  width: 107px;
  height: 38px;
  background-color: #000;
  border: 1px solid #000;
}

.rate-review .top-review h2 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 20px;
}

.rate-review .review-card {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 28px #00000000;
  border: 0.5px solid #b7b7b7;
  border-radius: 10px;
  padding: 7px 4px 7px 16px;
  min-height: 161px;
  height: 88%;
}

.rate-review .review-card .date-holder span {
  background: #f2f2f2;
  border-radius: 10px;
  font-size: 10px;
  padding: 4px;
}

.rate-review .review-card .name-text h3 {
  font-size: 16px;
  font-weight: bold;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 1;
}

.rate-review .review-card .name-text p {
  font-size: 12px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 5;
  margin-bottom: 5px;
}

.rate-review .review-card .img-holder {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  height: 100%;
  overflow-x: scroll;
  width: 188px;
}

.rate-review .review-card .img-holder .img-card {
  width: 100%;
  height: 114px;
  border-radius: 5px;
  object-fit: contain;
}

@media(min-width:0px) and (max-width:767px) {
  .rate-review .rate-holder {
    margin-left: 11px;
  }

  .rate-review .rating .plus-btn {
    width: 40px;
    height: 32px;

  }
}