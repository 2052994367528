.our-story {
    margin: 3% 0% 5% 0%;
}

.our-story .sub-text {
    font-size: 14px;
    margin-bottom: 0px;
    margin-top: 10px;
}

.our-story .h5-quote {
    font-size: 24px;
    color: #f99a1a;
    margin-top: 20px;
}

.title-border {
    border: 1px solid #f99a1a;
    width: 40px;
    margin: auto;
}

.hot-title {
    font-size: 24px;
    font-weight: 700;
    color: #000000;
}

@media(min-width:992px) and (max-width:1400px) {
    .our-story .h5-quote {
        font-size: 20px;
    }

    .our-story {
        margin: 5% 0% 5% 0%;
    }
}

@media(min-width:768px) and (max-width:991px) {
    .our-story .h5-quote {
        font-size: 18px;
    }

    .our-story {
        margin: 7% 0% 5% 0%;
    }
}

@media(min-width:0px) and (max-width:767px) {
    .our-story .h5-quote {
        font-size: 16px;
    }

    .hot-title {
        font-size: 20px;
        margin-top: 10px;
    }

    .our-story .sub-text {
        font-size: 13px;
    }

    .our-story {
        margin: 10% 0% 5% 0%;
    }
}