.select-by-category {
    margin-top: 2%;
    margin-bottom: 12%;
}

.select-by-category .heading-holder {
    margin-bottom: 2%;
}
@media(min-width:0px) and (max-width:321px) {
    .select-by-category {
        margin-bottom: 40%;
    }
}

@media(min-width:321px) and (max-width:486px) {
    .select-by-category {
        margin-bottom: 35%;
    }
}

@media(min-width:486px) and (max-width:567px) {
    .select-by-category {
        margin-bottom: 30%;
    }
}

@media(min-width:568px) and (max-width:767px) {
    .select-by-category {
        margin-bottom: 25%;
    }
}

@media(min-width:768px) and (max-width:992px) {
    .select-by-category {
        margin-bottom: 20%;
    }

}

@media(min-width:992px) and (max-width:1200px) {
    .select-by-category {
        margin-bottom: 15%;
    }

}