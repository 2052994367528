/* Shop-By-Seller Css Started */



.Shop-By-Seller {
  margin-top: 3%;
  margin-bottom: 4%;
}

.Shop-By-Seller .Seller-section {
  margin-top: 5%;
}

.Shop-By-Seller .main-section {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
}

.Shop-By-Seller .main-section .seller-img {
  border-radius: 10px;
  height: 100%;
  width: 100%;
  transition: 0.3s;
  aspect-ratio: 1/1;
  object-fit: contain;
}

.Shop-By-Seller .main-section:hover .seller-img {
  transform: scale(1.1);
  transition: 0.3s;
}

.Shop-By-Seller .main-section .bg-overlay {
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 60%;
  background: transparent linear-gradient(180deg, #0F376800 0%, #031A36D2 58%, #00142B 100%) 0% 0% no-repeat padding-box;
  border-radius: 10px;
}

.Shop-By-Seller .main-section .text-holder {
  position: absolute;
  bottom: 0px;
  left: 24px;
  z-index: 1;
}

.Shop-By-Seller .main-section .text-holder .local-text {
  font-size: 18px;
  font-weight: 500;
  color: #FFF;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;
}


/* 
.Shop-By-Seller .swiper-pagination-bullet-active {
  opacity: var(--swiper-pagination-bullet-opacity, 1);
  background: #df4223;
}

.Shop-By-Seller .swiper-pagination-bullet {
  background: #df4223;
  opacity: 1;
} */

/* Shop-By-Seller Css End */

@media(min-width:0px) and (max-width:991px) {
  .Shop-By-Seller .main-section .text-holder .local-text {
    font-size: 16px;
  }
}

@media(min-width:0px) and (max-width:768px) {
  .Shop-By-Seller {
    margin-top: 7%;
}
}