/* Frequently-Bought CSS Started */

.Frequently-Bought {
  height: 657px;
  background: #f3f5f7 0% 0% no-repeat padding-box;
  margin-bottom: 33px;
}

.Frequently-Bought .silder-btn {
  position: relative;
}

/* .Frequently-Bought .silder-btn .back-btn {
  content: url("../../../../public/assets/Image/Homepage/Popular-Categories/left.png");
  width: 20px;
  height: 20px;
  margin: 10px;
  cursor: pointer;
  position: absolute;
  bottom: 155px;
  z-index: 999;
  left: -23px;
}

.Frequently-Bought .silder-btn .next-btn {
  content: url("../../../../public/assets/Image/Homepage/Popular-Categories/right.png");
  width: 20px;
  height: 20px;
  margin: 10px;
  cursor: pointer;
  position: absolute;
  bottom: 155px;
  z-index: 999;
  right: -22px;
} */

.Frequently-Bought .swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
  display: none;
}

.Frequently-Bought .swiper-button-prev:after,
.swiper-button-next:after {
  display: none;
}

/* .Frequently-Bought .swiper-pagination-bullet-active {
  opacity: var(--swiper-pagination-bullet-opacity, 1);
  background: #df4223;
}

.Frequently-Bought .swiper-pagination-bullet {
  background: #df4223;
  opacity: 1;
} */

.Frequently-Bought h1.title {
  padding-top: 48px;
}

.Frequently-Bought .swiper {
  padding-top: 33px;
  padding-bottom: 0%;
  padding-left: 3px;
  padding-right: 3px;
}

/* Frequently-Bought CSS End */

/* media Query Started Of Second Vendor */

@media (min-width: 0px) and (max-width: 485px) {
  .Frequently-Bought .silder-btn .next-btn {
    right: -18px;
  }

  .Frequently-Bought .silder-btn .back-btn {
    left: -18px;
  }

  /* .Frequently-Bought {
    height: 550px;
  } */
}

@media (min-width: 486px) and (max-width: 991px) {
  /* .Frequently-Bought {
    height: 560px;
  } */
}
