.cctv-innerpage {
  margin-top: 10%;
}

.cctv-innerpage .cards-section .cards {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000078;
  opacity: 1;
  min-height: 281px;
  height: 100%;
}

.cctv-innerpage .cards-section {
  margin: 35px 20px;
}

.cctv-innerpage .cards-section .inner-card {
  padding: 9% 0% 5% 20%;
}

.cctv-innerpage .cards-section .inner-card .boxes {
  background-color: #ed1b24;
  height: 6px;
  width: 6px;
  left: 11%;
}

.cctv-innerpage .cards-section .inner-card .black-line {
  width: 1.5px;
  height: 41px;
  background-color: #bdbdbd;
  margin: 0px 2px;
}

.cctv-innerpage .cards-section .inner-card .onsite {
  margin-top: -6px;
}

.cctv-innerpage .cards-section .inner-card .onsite .scale {
  padding: 3px 0px;
}

.cctv-innerpage .card-sec {
  height: auto;
  margin-left: auto;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 10px;
  padding: 2px 0px;
}

/* .cctv-innerpage .card-sec:hover .product-img {
  transform: scale(1.1);
} */

/* .cctv-innerpage .card-sec .hearticn {
  width: 36px;
  height: 36px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 5px #00000029;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  float: right;
 margin: 8px 8px 0px 0px;
}

.cctv-innerpage .card-sec .hearticn .gobiheart {
  font-size: 18px;
  color: #b4b2b2;
}
.cctv-innerpage .card-sec .hearticn .gobiheartss{
  font-size: 18px;
  color: #e90a0a;
} */
.cctv-innerpage .card-sec .product-detail {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: contain !important;
  aspect-ratio: 1/1;
  background-position: center;
}

.cctv-innerpage .card-sec .product-img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  aspect-ratio: 1/1;
  transition: transform 0.5s;
}

.cctv-innerpage .mySwiper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 35px 0px 35px 0px;
  margin-left: 2%;
}

.cctv-innerpage .camera-imgs {
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: contain !important;
  aspect-ratio: 1/1;
  background-position: center;
  padding: 3% 0;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 7px;
}

.cctv-innerpage .camera-imgs .inner-img {
  width: 100%;
  height: 65px;
  object-fit: contain;
  aspect-ratio: 1/1;
}

.cctv-innerpage .main-slider-cctv {
  position: relative;
}

.cctv-innerpage .main-slider-cctv .arrow-back,
.main-slider-cctv .arrow-next {
  position: absolute;
  bottom: -80px;
  left: 33px;
  right: 83px;
  cursor: pointer;
}

.cctv-innerpage .main-slider-cctv .arrow-back {
  content: url("../../../../../public/assets/Image/Icon/prev.png");
  position: absolute;
  top: -80px;
  left: 0;
  z-index: 1;
  width: 13px;
}

.cctv-innerpage .main-slider-cctv .arrow-next {
  content: url("../../../../../public/assets/Image/Icon/next.png");
  position: absolute;
  top: -80px;
  left: 383px;
  z-index: 1;
  width: 13px;
}

.cctv-innerpage .swiper-button-prev {
  left: 0%;
  position: absolute;
  display: none;
}

.cctv-innerpage .IR-hover-sec:hover {
  background: #e5e5e5;
  opacity: 1;
}

.cctv-innerpage .IR-hover-sec {
  padding: 10px 0px 10px 35px;
  margin-right: 5%;
}

.cctv-innerpage .cards-section .cards .surv-text {
  margin-top: 16px;
}

/* ***********media query start ***************** */
@media (min-width: 0px) and (max-width: 320px) {
  .cctv-innerpage .card-sec .product-detail {
    height: 250px;
  }

  .cctv-innerpage .card-sec .product-img {
    width: 150px;
  }

  .cctv-innerpage {
    margin-top: 23%;
  }

  .cctv-innerpage .mySwiper {
    padding: 10px 0px 11px 0px;
  }

  .cctv-innerpage .cards-section .cards {
    min-height: 0px;
  }

  .cctv-innerpage .camera-imgs {
    margin: 5px;
  }

  .cctv-innerpage .card-sec {
    margin: auto;
  }

  .cctv-innerpage .IR-hover-sec {
    margin: 3% 2% 1% 6%;
    padding: 10px 10px 10px 20px;
  }

  .cctv-innerpage .main-slider-cctv .arrow-next::before {
    right: 17px;
  }

  .cctv-innerpage .cards-section .inner-card .black-line {
    height: 35px;
  }

  .cctv-innerpage .cards-section .cards .security {
    margin-top: -6px;
  }

  .cctv-innerpage .card-sec:hover .product-img {
    transform: scale(1.2);
  }

  .cctv-innerpage .cards-section .cards .surv-text {
    margin-top: 11px;
  }

  .cctv-innerpage .main-slider-cctv .arrow-back {
    top: -60px;
    left: 2px;
  }

  .cctv-innerpage .main-slider-cctv .arrow-next {
    top: -60px;
    left: unset;
    right: -1px;
  }
}

@media (min-width: 321px) and (max-width: 485px) {
  .cctv-innerpage .card-sec .product-detail {
    height: auto;
  }

  .cctv-innerpage .mySwiper {
    padding: 15px 0px 15px 0px;
  }

  .cctv-innerpage .cards-section .cards {
    min-height: 0px;
  }

  .cctv-innerpage .cards-section .inner-card {
    padding: 9% 0% 5% 26%;
  }

  .cctv-innerpage .camera-imgs {
    margin: 5px;
  }

  .cctv-innerpage .card-sec {
    margin: auto;
  }

  .cctv-innerpage {
    margin-top: 16%;
  }

  .cctv-innerpage .IR-hover-sec {
    margin: 3% 2% 1% 6%;
    padding: 10px 10px 10px 20px;
  }

  .cctv-innerpage .main-slider-cctv .arrow-next::before {
    right: 0px;
  }

  .cctv-innerpage .card-sec .product-detail {
    height: 250px;
  }

  .cctv-innerpage .card-sec .product-img {
    width: 160px;
  }

  .cctv-innerpage .cards-section .inner-card .black-line {
    height: 34px;
  }

  .cctv-innerpage .cards-section .cards .security {
    margin-top: -9px;
  }

  .cctv-innerpage .card-sec:hover .product-img {
    transform: scale(1.2);
  }

  .cctv-innerpage .cards-section .cards .security {
    margin-top: -9px;
  }

  .cctv-innerpage .card-sec:hover .product-img {
    transform: scale(1.2);
  }

  .cctv-innerpage .cards-section .cards .surv-text {
    margin-top: 14px;
  }
}

@media (min-width: 321px) and (max-width: 420px) {
  .cctv-innerpage .main-slider-cctv .arrow-back {
    top: -70px;
    left: 7px;
  }

  .cctv-innerpage .main-slider-cctv .arrow-next {
    top: -70px;
    left: unset;
    right: 0px;
  }
}

@media (min-width: 421px) and (max-width: 485px) {
  .cctv-innerpage .main-slider-cctv .arrow-back {
    top: -78px;
    left: 7px;
  }

  .cctv-innerpage .main-slider-cctv .arrow-next {
    top: -79px;
    left: unset;
    right: 0px;
  }
}

@media (min-width: 421px) and (max-width: 485px) {
  .cctv-innerpage .main-slider-cctv .arrow-back {
    top: -78px;
    left: 7px;
  }

  .cctv-innerpage .main-slider-cctv .arrow-next {
    top: -79px;
    left: unset;
    right: 0px;
  }
}

@media (min-width: 486px) and (max-width: 575px) {
  .cctv-innerpage .card-sec .product-detail {
    height: auto;
  }

  .cctv-innerpage .cards-section .inner-card {
    padding: 4% 0% 5% 25%;
  }

  .cctv-innerpage .cards-section .cards {
    min-height: 0px;
  }

  .cctv-innerpage .card-sec {
    margin: auto;
  }

  .cctv-innerpage {
    margin-top: 16%;
  }

  .cctv-innerpage .IR-hover-sec {
    margin: 3% 2% 1% 6%;
    padding: 10px 10px 10px 20px;
  }

  .cctv-innerpage .card-sec .product-detail {
    height: 250px;
  }

  .cctv-innerpage .card-sec .product-img {
    width: 165px;
  }

  .cctv-innerpage .cards-section .inner-card .black-line {
    height: 39px;
  }

  .cctv-innerpage .card-sec:hover .product-img {
    transform: scale(1.2);
  }

  .cctv-innerpage .main-slider-cctv .arrow-back {
    top: -111px;
  }

  .cctv-innerpage .main-slider-cctv .arrow-next {
    top: -111px;
    left: unset;
    right: 0px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .cctv-innerpage .card-sec .product-detail {
    height: auto;
  }

  .cctv-innerpage .cards-section .inner-card {
    padding: 6% 0% 5% 25%;
  }

  .cctv-innerpage .cards-section .cards {
    min-height: 0px;
  }

  .cctv-innerpage .card-sec {
    margin: auto;
  }

  .cctv-innerpage {
    margin-top: 13%;
  }

  .cctv-innerpage .IR-hover-sec {
    margin: 3% 2% 1% 6%;
    padding: 10px 10px 10px 20px;
  }

  .cctv-innerpage .main-slider-cctv .arrow-back::after {
    left: -32px;
  }

  .cctv-innerpage .card-sec .product-detail {
    height: 250px;
  }

  .cctv-innerpage .card-sec .product-img {
    width: 165px;
  }

  .cctv-innerpage .cards-section .inner-card .black-line {
    height: 40px;
  }

  .cctv-innerpage .main-slider-cctv .arrow-back {
    top: -112px;
  }

  .cctv-innerpage .main-slider-cctv .arrow-next {
    top: -112px;
    left: 485px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .cctv-innerpage .cards-section .inner-card {
    padding: 9% 0% 5% 10%;
  }

  .cctv-innerpage .IR-hover-sec {
    margin-top: 4%;
  }

  .cctv-innerpage .card-sec {
    margin: auto;
  }

  .cctv-innerpage .main-slider-cctv .arrow-back {
    top: -95px;
  }

  .cctv-innerpage .main-slider-cctv .arrow-next {
    top: -95px;
    left: 667px;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .cctv-innerpage .camera-imgs .inner-img {
    width: 84px;
  }

  .cctv-innerpage .main-slider-cctv .arrow-next {
    left: 270px;
  }
}

@media (min-width: 1201px) and (max-width: 1400px) {
  .cctv-innerpage .camera-imgs .inner-img {
    width: 82px;
  }

  .cctv-innerpage .card-sec {
    margin: auto;
  }

  .cctv-innerpage .main-slider-cctv .arrow-back {
    /* top: -78px; */
  }

  .cctv-innerpage .main-slider-cctv .arrow-next {
    left: 328px;
  }
}
