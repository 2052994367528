.Category_Card .image-holder {
  position: relative;
  text-align: center;
}

.Category_Card .image-holder .banlogo {
  width: 100%;
  height: 269px;
}

.Category_Card .image-holder .text-overlay {
  position: absolute;
  top: 28px;
  left: 0;
  z-index: 1;
  right: 0;
}
