.EmailOtpModal .modal-header {
    border-bottom: 0px solid #dee2e6;
}

.EmailOtpModal .modal-content {
    border: 0px;
    border-radius: 9px;
    box-shadow: 0px 0px 5px 0px #ccc;
}

.EmailOtpModal .logoutdbtn {
    background: #e40046;
    border: 0px;
    padding: 10px 20px;
    border-radius: 25px;
    width: 100%;
    color: #fff;
}

.EmailOtpModal .Cancelbtn {
    background: #e2e2e2;
    border: 0px;
    padding: 10px 20px;
    border-radius: 25px;
    width: 100%;
}

.EmailOtpModal .Verify {
    background: #e40046;
    color: #fff;
    border: 0px;
    padding: 10px 20px;
    border-radius: 25px;
    width: 100%;
}

.EmailOtpModal .modal-title {
    font-weight: 500;
    font-size: 18px;
    color: #000;
    text-align: center;
}

.EmailOtpModal .modal-title p {
    font-weight: 500;
    font-size: 15px;
    color: #646262;
    text-align: center;
}

.EmailOtpModal .get-otp p {
    font-weight: 500;
    font-size: 15px;
    color: #646262;
    text-align: center;
}

.EmailOtpModal .modal-body .get-otp span {
    font-weight: 400;
    font-size: 18px;
    color: #e40046;
    text-align: center;
    text-decoration: underline;
    margin-left: 5px;
}

.EmailOtpModal .modal-body .otp_input {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 5%;
}

.EmailOtpModal .modal-body .otp_input .opt-input-field {
    width: 50px;
    height: 50px;
    text-align: center;
    font-size: 30px;
    font-weight: 500;
    color: #1c42a9;
}