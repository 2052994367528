.Home-Banner img.Banner-image {
  height: auto;
  width: 100%;
}

/* .Home-Banner .swiper-pagination-bullet-active {
  opacity: var(--swiper-pagination-bullet-opacity, 1);
  background: #df4223;
}

.Home-Banner .swiper-pagination-bullet {
  background: #df4223;
  opacity: 1;
} */
