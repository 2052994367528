.first-vendor-details .nav-pills .nav-link {
  color: #333333;
  font-size: 15px;
  font-weight: 500;
}

.first-vendor-details .nav-pills .nav-link.active {
  color: #f99a1a;
  font-size: 15px;
  border-bottom: 2px solid #f99a1a;
  border-radius: 0px;
  background: none;
  font-weight: 600;
}

.first-vendor-details .nav.nav-pills {
  border-bottom: 2px solid #a2a2a2;
}

.first-vendor-details .button-effect .heart-icon.active {
  color: #f99a1a;
}

.first-vendor-details .red-seller {
  font-size: 11px;
  color: #f99a1a;
  text-decoration: underline;
}

.first-vendor-details .description-class .description-class-title {
  font-size: 20px;
  color: #333333;
  font-weight: 600;
}

.first-vendor-details .add-to-cart-btn {
  border: 1px solid #0f3768;
  background: transparent;
  border-radius: 25px;
  /* padding: 5px 20px; */
  font-size: 14px;
  color: #333333;
  font-weight: 500;
  width: 168px;
  height: 38px;
}

.first-vendor-details .add-to-cart-btn:hover {
  background: #0f3768;
  border-radius: 25px;
  /* padding: 5px 20px; */
  font-size: 14px;
  color: #ffffff;
  font-weight: 500;
  width: 168px;
  height: 38px;
}

.first-vendor-details .button-effect {
  /* position: relative; */
  display: flex;
  align-items: center;
}

.first-vendor-details .blue-bg {
  /* position: absolute; */
  bottom: 0px;
  background: #fff;
  border: 1px solid #0f3768;
  border-radius: 50px;
  width: 168px;
  height: 38px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.first-vendor-details .circle-holder1 {
  border-radius: 100%;
  background: #fff;
  width: 32px;
  height: 32px;
  margin: 3px;
}

.first-vendor-details .blue-bg .minus-btn {
  border-radius: 100%;
  color: #212529;
  background: #c9c6c6;
  border: 1px solid #ccc;
  /* padding: 0px 11px; */
  width: 28px;
  height: 28px;
  margin: 2px;
}

.first-vendor-details .blue-bg .minus-btn .minus-icon {
  color: #fff;

}

.first-vendor-details .number-text {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 0px;
  font-size: 18px;
  font-weight: 500;
  color: #333333;
}

.first-vendor-details .title-details .in-stock {
  font-size: 12px;
  color: #008f18;
  font-weight: 500;
}

.first-vendor-details .title-details .mrp-class {
  font-size: 15px;
  color: #f99a1a;
  font-weight: 500;
  margin-bottom: 0px;
}

.first-vendor-details .title-details .mrp-class span {
  font-size: 12px;
  color: #333333;
}

.first-vendor-details .title-details .return-mrp {
  font-size: 12px;
  color: #333333;
  text-decoration: line-through;
  margin-bottom: 2px;
}

.first-vendor-details .title-details h2 {
  font-size: 20px;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
}

.first-vendor-details .text-holder-vendor-page {
  font-size: 15px;
  color: #333333;
  text-align: justify;
}

.first-vendor-details .brod-title {
  font-size: 15px;
  color: #333333;
  font-weight: 600;
}

.first-vendor-details .brod-subtitle {
  font-size: 15px;
  color: #333333;
}

.first-vendor-details .brod-title-2 {
  font-size: 12px;
  color: #333333;
  font-weight: 600;
  margin-bottom: 3px;
}

.first-vendor-details .brod-subtitle-2 {
  font-size: 12px;
  color: #333333;
  margin-bottom: 3px;
}

.first-vendor-details .orange-bg-details {
  background: #ffc5b9;
  border-radius: 5px;
  padding: 10px 15px;
  margin-top: 3%;
}

.first-vendor .bred-cumb-vendor a {
  font-size: 13px;
  color: #0f3768;
  font-weight: 700;
}

.first-vendor .bred-cumb-vendor .breadcrumb-item.active {
  color: #333333;
  font-size: 13px;
  font-weight: 500;
  padding-top: 3px;
}

.first-vendor .breadcrumb-item+.breadcrumb-item::before {
  content: url("../../../../../public/assets/Image/Icon/arrow-forward.png");
}

.button-effect .qntybtns {
  margin-left: 50px;
  align-items: center;
}

.button-effect .qntybtns .pluss-btn {
  width: 30px;
  height: 30px;
  background: #c9c6c6 0% 0% no-repeat padding-box;
  opacity: 1;
  border-radius: 50%;
  border: none;
}

.button-effect .qntybtns .min-btns {
  background: #d1d1d1 0% 0% no-repeat padding-box;
  color: #fff;
}

.button-effect .cardcounts {
  margin: 0px 10px;
}

.variationdivvvv .nav.nav-pills {
  border-bottom: 0px solid #a2a2a2;
}

.variationdivvvv .nav .nav-link {
  min-width: 20px;
  height: 20px;
  border-radius: 100px;
  padding: 0px;
  border: 2px solid transparent;
  margin: 0px 5px;
}

.variationdivvvv .nav .nav-link.active {
  /* border: 1px solid #000; */
  min-width: 20px;
  height: 20px;
  border-radius: 100px;
  padding: 0px;
  outline-offset: 2px;
  outline: 2px solid #000;
  border-bottom: 0px;
}

.variationdivvvv .nav .col1 {
  background-color: #008f18 !important;
}

.variationdivvvv .nav .col2 {
  background-color: #df4223 !important;
}

.variationdivvvv .nav .col3 {
  background-color: #0f3768 !important;
}




.variationdivvvvsize .nav.nav-pills {
  border-bottom: 0px solid #a2a2a2;
}


.variationdivvvvsize .nav .nav-link {
  padding: 5px 8px;
  margin: 0px 2px;
  font-size: 12px;
  color: #000;
  border-radius: 3px;
  background-color: #f1f1f1;
}

.variationdivvvvsize .nav .nav-link.active {
  background-color: #fbaf1a;
}

/* Media */
@media (min-width: 0px) and (max-width: 320px) {
  .first-vendor-details .title-details h2 {
    font-size: 15px;
  }

  .first-vendor-details .title-details .mrp-class {
    font-size: 12px;
  }

  .first-vendor-details .title-details .mrp-class span {
    font-size: 10px;
  }

  .first-vendor-details .add-to-cart-btn {
    font-size: 12px;
    font-weight: 500;
    width: 127px;
  }

  .first-vendor-details .add-to-cart-btn:hover {
    font-size: 12px;
    font-weight: 500;
    width: 127px;
  }

  .first-vendor-details .description-class .description-class-title {
    font-size: 16px;
  }

  .first-vendor-details .nav-pills .nav-link {
    font-size: 12px;
  }

  .first-vendor-details .nav-pills .nav-link.active {
    font-size: 12px;
  }

  .first-vendor-details .text-holder-vendor-page {
    font-size: 12px;
  }

  .first-vendor-details .blue-bg {
    width: 133px;
  }

  /* .first-vendor-details .button-effect {
    display: unset;
  } */

  .button-effect .qntybtns {
    margin-left: 0px;
    margin-top: 20px;
    align-items: center;
  }
}

@media (min-width: 321px) and (max-width: 485px) {
  .first-vendor-details .title-details h2 {
    font-size: 15px;
  }

  .first-vendor-details .title-details .mrp-class {
    font-size: 12px;
  }

  .first-vendor-details .title-details .mrp-class span {
    font-size: 10px;
  }

  .first-vendor-details .add-to-cart-btn {
    font-size: 12px;
    font-weight: 500;
    width: 127px;
  }

  .first-vendor-details .add-to-cart-btn:hover {
    font-size: 12px;
    font-weight: 500;
    width: 127px;
  }

  .first-vendor-details .blue-bg {
    width: 127px;
    top: -8px;
  }

  .first-vendor-details .description-class .description-class-title {
    font-size: 16px;
  }

  .first-vendor-details .nav-pills .nav-link {
    font-size: 13px;
  }

  .first-vendor-details .nav-pills .nav-link.active {
    font-size: 13px;
  }

  .first-vendor-details .text-holder-vendor-page {
    font-size: 13px;
  }

  .first-vendor-details .button-effect {
    display: unset;
  }

  .button-effect .qntybtns {
    margin-left: 0px;
    margin-top: 20px;
    align-items: center;
  }
}

@media (min-width: 486px) and (max-width: 576px) {
  .first-vendor-details .title-details h2 {
    font-size: 17px;
  }

  .first-vendor-details .title-details .mrp-class {
    font-size: 13px;
  }

  .first-vendor-details .title-details .mrp-class span {
    font-size: 11px;
  }

  .first-vendor-details .add-to-cart-btn {
    font-size: 12px;
    font-weight: 500;
    width: 127px;
  }

  .first-vendor-details .add-to-cart-btn:hover {
    font-size: 12px;
    font-weight: 500;
    width: 127px;
  }

  .first-vendor-details .blue-bg {
    width: 133px;
  }

  .first-vendor-details .description-class .description-class-title {
    font-size: 17px;
  }

  .first-vendor-details .nav-pills .nav-link {
    font-size: 13px;
  }

  .first-vendor-details .nav-pills .nav-link.active {
    font-size: 13px;
  }

  .first-vendor-details .text-holder-vendor-page {
    font-size: 13px;
  }
}

@media (min-width: 577px) and (max-width: 768px) {
  .first-vendor-details .title-details h2 {
    font-size: 17px;
  }

  .first-vendor-details .title-details .mrp-class {
    font-size: 13px;
  }

  .first-vendor-details .title-details .mrp-class span {
    font-size: 11px;
  }

  .first-vendor-details .description-class .description-class-title {
    font-size: 17px;
  }

  .first-vendor-details .nav-pills .nav-link {
    font-size: 13px;
  }

  .first-vendor-details .nav-pills .nav-link.active {
    font-size: 13px;
  }

  .first-vendor-details .text-holder-vendor-page {
    font-size: 13px;
  }
}