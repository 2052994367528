.callBackPage .success-lottie-text-holder {
    display: flex;
    justify-content: center;
    align-items: center;
}

.callBackPage {
    margin-bottom: 17%;
}

.callBackPage p {
    font-size: 24px;
    color: #000;
    font-weight: 600;
    text-align: center;
}






@media (min-width: 0px) and (max-width: 320px) {
    .callBackPage {
        margin-bottom: 50%;
    }

    .callBackPage p {
        font-size: 15px;
    }

    .callBackPage .Successfull_Lottie_icon {
        width: 100px;
        height: 100px;
    }
}


@media (min-width: 321px) and (max-width: 485px) {
    .callBackPage {
        margin-bottom: 50%;
    }

    .callBackPage p {
        font-size: 17px;
    }

    .callBackPage .Successfull_Lottie_icon {
        width: 100px;
        height: 100px;
    }
}



@media (min-width: 486px) and (max-width: 575px) {
    .callBackPage {
        margin-bottom: 50%;
    }

    .callBackPage p {
        font-size: 21px;
    }

    .callBackPage .Successfull_Lottie_icon {
        width: 100px;
        height: 100px;
    }
}


@media (min-width: 576px) and (max-width: 767px) {
    .callBackPage {
        margin-bottom: 50%;
    }

    .callBackPage p {
        font-size: 20px;
    }

    .callBackPage .Successfull_Lottie_icon {
        width: 100px;
        height: 100px;
    }

}


@media (min-width: 768px) and (max-width: 991px) {
    .callBackPage {
        margin-bottom: 50%;
    }

    .callBackPage p {
        font-size: 20px;
    }

    .callBackPage .Successfull_Lottie_icon {
        width: 100px;
        height: 100px;
    }

}