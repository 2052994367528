.our-product {
  margin-bottom: 6%;
}

.our-product .view-all {
  margin-top: 2%;
}

.our-product .view-all p {
  width: fit-content;
}

.our-product .view-all {
  text-align: -webkit-right;
}

.our-product  .our-product-slider {
  padding: 10px 0px;
}

.our-product .desktop_view_product_category_slider {
  display: block;
}

.our-product .mobile_view_product_category_slider {
  display: block;
}

@media (min-width: 0px) and (max-width: 991px) {
  .our-product .product-image .text-holder p {
    font-size: 14px;
  }

  .our-product .desktop_view_product_category_slider {
    display: none;
  }

  .our-product .mobile_view_product_category_slider {
    display: block;
  }
}
