.Veiw_All_Card {
  background: #f99a1a !important;
  padding: 0px 0px 0px 0px;
  display: flex;
  justify-content: start;
  align-items: center;
  width: 100%;
}

.Veiw_All_Card .Veiw_All_Card_content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  width: 50%;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.Veiw_All_Card .Veiw_All_Card_content .view-text-holder p {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0px;
}
